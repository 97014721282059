import React, { useEffect, useState } from 'react';
import AdminLayout from '@components/MainPage/AdminLayout';
import {
  Card,
  CardContent,
  Container,
  Button,
  Grid,
  Box,
  Icon,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CustomTableOficios from '@components/Tables/CustomTableOficios';
import AutocompleteSelect from '@components/Oficialia/AutocompleteSelect';
import { getVars } from '@utils/global';

import OficialiaServices from '@services/OficialiaServices';
import Swal from 'sweetalert2';
import FilterOficialia from '@components/Oficialia/Filter';
import middleware from '@middlewares/middleware';
import Download from '@components/Downloads/Download';
import MessageAlert from '@components/Downloads/MessageAlert';
import CardStatsOficialia from '@components/Card/CardStatsOficialia';

import './OficialiaPartes.css';

const OficiliaPartes = () => {
  const { user } = getVars('Token');
  const navigate = useNavigate();
  const [statsOficios, setStatsOficios] = useState({
    EnTransito: 0,
    TotalOficios: 0,
    TotalOficiosActivos: 0,
    OficiosExpirados: 0,
    ProximosAExpirar: 0,
  });
  const [showUser, setShowUser] = useState({
    Agregar: 0,
    Editar: 0,
    Recibir: 0,
    Seguimiento: 0,
    Turnar: 0,
    Ver: 0,
    idUsuarioPermitido: user.id,
    idUsuarioPropietario: user.id,
    UUIDPropietario: user.UUID,
    UsuarioPropietario: user.Nombre,
  });
  const [data, setData] = useState([]);
  const [dataUserPermisos, setDataUserPermisos] = useState([]);
  const [dataTotal, setDataTotal] = useState(0);
  const [params, setParams] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
    sorted: [{ id: 'o.FechaRecibido', value: 'DESC' }],
  });

  const [isLoading, setLoading] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [successDownload, setSuccessDownload] = useState(false);

  useEffect(() => {
    setParams({
      page: 0,
      pageSize: 10,
      filtered: [],
      sorted: [{ id: 'o.FechaRecibido', value: 'DESC' }],
    });
  }, [showUser]);

  const columns = [
    //{ id: "row1", label: "Numeros", type: "number", fixed: 2 },
    { id: 'Fecha', label: 'Fecha', type: 'html', minWidth: 100, maxWidth: 180 },
    {
      id: 'Oficio',
      label: 'Oficio',
      type: 'html',
      icon: 'reply',
      iconColor: '#000F9F',
      iconSize: 20,
      minWidth: 150,
      maxWidth: 400,
    },
    { id: 'DeQuien', label: 'De' },
    { id: 'ParaQuien', label: 'Para' },
    {
      id: 'Estatus',
      label: 'Estatus',
    },
  ];

  const getData = async () => {
    try {
      if (isLoading) return; // Previene llamadas múltiples
      setLoading(true);
      var Filtros = params.filtered;
      if (params.filtered.length) {
        console.log('=>middleware.checkMenuActionId ', middleware.checkMenuActionId('admin'));
        if (!middleware.checkMenuActionId('admin')) {
          const newFilter = {
            id: ` and o.id in (select idOficio from cde_oficialia_recepcion where idEstatus=1 and (idUsuarioRecibe=${showUser.idUsuarioPropietario} or idDeQuien=${showUser.idUsuarioPropietario}) and FechaElimino is null)`,
            filterQuery: true,
          };

          const existe = params.filtered.some((o) => o.id === 'o.id in');
          /* const filterExists = params.filtered.some(
            (filter) => filter.id === newFilter.id && filter.filterQuery
          ); */
          if (!existe) {
            Filtros.push(newFilter);
          }
        } else {
          if (!middleware.checkMenuActionId('admin')) {
            Filtros.push({
              id: ` where o.id in (select idOficio from cde_oficialia_recepcion where idEstatus=1 and (idUsuarioRecibe=${showUser.idUsuarioPropietario} or idDeQuien=${showUser.idUsuarioPropietario}) and FechaElimino is null)`,
              filterQuery: true,
            });
          }
        }
      } else {
        if (!middleware.checkMenuActionId('admin')) {
          const newFilterN = {
            id: ` where o.id in (select idOficio from cde_oficialia_recepcion where idEstatus=1 and (idUsuarioRecibe=${showUser.idUsuarioPropietario} or idDeQuien=${showUser.idUsuarioPropietario}) and FechaElimino is null)`,
            filterQuery: true,
          };

          Filtros.push(newFilterN);
        }
      }

      Filtros = Filtros.filter((filter) => !filter.id.includes('undefined'));
      console.log('=>getOficialia: ', { ...params, filtered: Filtros });
      console.log('=>admin: ', middleware.checkMenuActionId('admin'));

      const res = await OficialiaServices.getOficialia({ ...params, filtered: Filtros });
      const { results, response, message } = res;

      if (results) {
        let data = response.data.map((item) => {
          return {
            ...item,
            Estatus: (
              <div>
                <span
                  style={{
                    fontWeight: 'bold',
                    fontSize: 'smaller',
                    cursor: 'pointer',
                  }}
                  onClick={() => navigate(`/oficialia-ver/${item.UUID}`)}
                >
                  Estatus: &nbsp;
                  <span style={{ fontSize: 'smaller', cursor: 'pointer' }}>{item.Estatus}</span>
                  <br />
                  <span style={{ fontWeight: 'normal', fontSize: 'small' }}>{item.TiempoRestante}</span>
                  <br />
                  <span
                    style={{
                      color: '#0041A0',
                      fontWeight: 'bold',
                      fontSize: 'small',
                    }}
                  >
                    Recibe: {item.UsuarioRecibe}
                  </span>
                </span>
              </div>
            ),
            Oficio: (
              <div>
                {item.TieneAdjuntos ? <Icon>attachment</Icon> : null}
                {item.NumOficio ? (
                  <>
                    <span
                      style={{
                        fontWeight: 'bold',
                        fontSize: 'smaller',
                        cursor: 'pointer',
                      }}
                      onClick={() => navigate(`/oficialia-ver/${item.UUID}`)}
                    >
                      Num Oficio: &nbsp;
                      <span style={{ fontSize: 'smaller', cursor: 'pointer' }}>{item.NumOficio}</span>
                      <br />
                      <span style={{ fontWeight: 'normal', fontSize: 'medium' }}>{item.Asunto}</span>
                    </span>
                  </>
                ) : (
                  <span
                    style={{
                      fontWeight: 'normal',
                      fontSize: 'medium',
                      cursor: 'pointer',
                    }}
                    onClick={() => navigate(`/oficialia-ver/${item.UUID}`)}
                  >
                    {item.Asunto}
                  </span>
                )}
              </div>
            ),
            ParaQuien: (
              <div>
                <span style={{ fontWeight: 'bold', fontSize: 'smaller' }}>{item.PersonaRecibe}</span>
                <br />
                <span style={{ fontSize: 'smaller' }}>{item.CargoPersonaRecibe}</span>
                <br />
                <span style={{ fontSize: 'smaller' }}>{item.EmpresaRecibe}</span>
              </div>
            ),
            DeQuien: (
              <div>
                <span style={{ fontWeight: 'bold', fontSize: 'smaller' }}>{item.PersonaEnvia}</span>
                <br />
                <span style={{ fontSize: 'smaller' }}>{item.CargoPersonaEnvia}</span>
                <br />
                <span style={{ fontSize: 'smaller' }}>{item.EmpresaEnvia}</span>
              </div>
            ),
            Fecha: (
              <div>
                <span style={{ fontWeight: 'bold', fontSize: 'smaller' }}>Oficio:</span>
                <br />
                <span style={{ fontSize: 'smaller' }}>{item.FechaOficio}</span>
                <br />

                {/* <span style={{ fontWeight: 'bold', fontSize: 'smaller' }}>F.Interno: </span>
                <span style={{ fontSize: 'smaller' }}>{item?.FolioInterno ? item.FolioInterno : 'SF'}</span>
                <br /> */}

                {item?.FolioInterno ? (
                  <>
                    <span style={{ fontWeight: 'bold', fontSize: 'smaller' }}>F.Interno:</span>
                    <span style={{ fontSize: 'smaller' }}>{item.FolioInterno}</span>
                    <br />
                  </>
                ) : (
                  <></>
                )}

                <span
                  style={{
                    fontWeight: 'bold',
                    fontSize: 'smaller',
                  }}
                >
                  Recibe:
                </span>
                <br />
                <span style={{ fontSize: 'smaller' }}>{item.FechaRecibido}</span>
              </div>
            ),
          };
        });
        setData(data);
        setDataTotal(response.total);
      } else {
        setData([]);
        setDataTotal(0);
        // Swal.fire({ title: message, icon: 'warning' });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Swal.fire({ title: error.message, icon: 'warning' });
    }
  };

  const getPermisosUsuarios = async () => {
    try {
      setLoading(true);

      const res = await OficialiaServices.getPermisosPersonasOficialia({
        page: 0,
        pageSize: 25,
        filtered: [{ id: 'p.idUsuarioPermitido', value: user.id, filter: '=' }],
      });
      const { results, response, message } = res;

      const userPermitidos = [
        {
          Agregar: 1,
          Editar: 1,
          Recibir: 1,
          Seguimiento: 1,
          Turnar: 1,
          UUIDPropietario: user.UUID,
          UsuarioPropietario: `${user.Nombre} ${user.Paterno}`,
          Ver: 1,
          idUsuarioPropietario: user.id,
        },
      ];

      if (results) {
        setDataUserPermisos(userPermitidos.concat(response.data));
      } else {
        setDataUserPermisos(userPermitidos);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Swal.fire({ title: error.message, icon: 'warning' });
    }
  };

  useEffect(() => {
    getData();
    getStatsTransito();
    // getTotales();
  }, [params]);

  const getStatsTransito = async () => {
    try {
      if (isLoading) return; // Previene llamadas múltiples
      setLoading(true);

      const res = await OficialiaServices.getStatsEnTransito({
        page: 0,
        pageSize: 99999,
        idUserReq: showUser.idUsuarioPropietario || user.id,
        filtered: [
          {
            id: ` and r.idEstatus=1 and ( r.idParaQuien = ${showUser.idUsuarioPropietario} or r.idDeQuien = ${showUser.idUsuarioPropietario})`,
            filterQuery: true,
          },
        ],
      });
      const { results, response, message } = res;

      if (results) {
        setStatsOficios(response.data);
      } else {
        setStatsOficios({
          EnTransito: 0,
          TotalOficios: 0,
          TotalOficiosActivos: 0,
          OficiosExpirados: 0,
          ProximosAExpirar: 0,
        });
        Swal.fire({ title: message, icon: 'warning' });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Swal.fire({ title: error.message, icon: 'warning' });
    }
  };

  useEffect(() => {
    getPermisosUsuarios();
  }, []);

  const handlePagination = ({ page, pageSize }) => {
    console.log('params', params);
    const params_constante = {
      ...params,
      page: page,
      pageSize: pageSize,
    };
    setParams(params_constante);
  };

  const handleSort = (e) => {
    /*       setOrderBy(e.orderBy);
        setOrder(e.order); */

    var OrdandoPor = [];

    if (e.orderBy === 'DeQuien') {
      OrdandoPor.push({ id: 'de.NombreCompleto', value: e.order });
    }

    if (e.orderBy === 'ParaQuien') {
      OrdandoPor.push({ id: 'dr.NombreCompleto', value: e.order });
    }

    if (e.orderBy === 'Fecha') {
      OrdandoPor.push({ id: 'o.FechaOficio', value: e.order });
    }

    if (e.orderBy === 'Oficio') {
      OrdandoPor.push({ id: 'o.Asunto', value: e.order });
    }

    const paramsNew = {
      ...params,
      sorted: OrdandoPor,
    };

    setParams(paramsNew);
  };

  const handleExport = () => {
    setIsDownloading(true);
    OficialiaServices.exportMecanismos({
      page: 0,
      pageSize: 99999999999999,
      filtered: params.filtered,
    })
      .then((res) => {
        if (res.success) {
          setShowMessage(true);
          setSuccessDownload(true);
        } else {
          setShowMessage(true);
          setSuccessDownload(false);
        }
      })
      .catch((error) => {
        setShowMessage(true);
        setSuccessDownload(false);
      })
      .finally(() => setIsDownloading(false));
  };

  const handleConTermino = async (e) => {
    setShowMore(e);

    const filtros = params.filtered;

    if (filtros.length > 0) {
      if (showMore) {
        const newFilter = {
          id: ' and o.id not in (select idOficio from cde_oficialia_seguimiento where FechaElimino is null)',
          filterQuery: true,
        };

        const filterExists = filtros.some((filter) => filter.id === 'o.id in');
        //const filterExists = filtros.some((filter) => filter.id === newFilter.id && filter.filterQuery);

        if (!filterExists) {
          filtros.push(newFilter); // Agrega el nuevo filtro si no existe
        }
      }
      if (middleware.checkMenuActionId('admin') === 1) {
        filtros.push({
          id: ` and o.id in (select idOficio from cde_oficialia_recepcion where idEstatus=1 and idUsuarioRecibe=${showUser} and FechaElimino is null)`,
          filterQuery: true,
        });
      }
    } else {
      if (showMore) {
        filtros.push({
          id: ' where o.id not in (select idOficio from cde_oficialia_seguimiento where FechaElimino is null)',
          filterQuery: true,
        });
        if (middleware.checkMenuActionId('admin') === 1) {
          filtros.push({
            id: ` and o.id in (select idOficio from cde_oficialia_recepcion where idEstatus=1 and idUsuarioRecibe=${showUser} and FechaElimino is null)`,
            filterQuery: true,
          });
        }
      } else {
        if (middleware.checkMenuActionId('admin') === 1) {
          filtros.push({
            id: ` where o.id in (select idOficio from cde_oficialia_recepcion where idEstatus=1 and idUsuarioRecibe=${showUser} and FechaElimino is null)`,
            filterQuery: true,
          });
        }
      }
    }
  };

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <Grid container spacing={2}>
          {/* Tarjetas principales */}
          <Grid item xs={12} sm={4}>
            <CardStatsOficialia
              icon="lightbulb"
              iconColor="#DC0019"
              title="Expiran"
              total={`${parseInt(statsOficios.OficiosExpirados)} de ${
                parseInt(statsOficios.OficiosExpirados) + parseInt(statsOficios.ProximosAExpirar)
              }`}
              subtitle="expirados"
              loading={isLoading}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <CardStatsOficialia
              icon="done_all"
              iconColor="#000F9F"
              title="Oficios"
              total={`${
                parseInt(statsOficios.TotalOficios) - parseInt(statsOficios.TotalOficiosActivos)
              } de ${parseInt(statsOficios.TotalOficios)}`}
              subtitle="concluidos"
              loading={isLoading}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <CardStatsOficialia
              icon="repeat"
              iconColor="#000F9F"
              title="En tránsito"
              total={`${parseInt(statsOficios.EnTransito)}`}
              loading={isLoading}
              onClick={() => navigate(`/oficialia-en-transito/${showUser.UUIDPropietario}`)}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3} sx={{ mt: 2 }}>
          <Card>
            <CardContent>
              <Grid item xs={12}>
                <FilterOficialia
                  showMore={showMore}
                  isAdmin={middleware.checkMenuActionId('admin')}
                  setFiltros={(e) => {
                    setParams({ ...params, page: 0, filtered: e });
                  }}
                />
              </Grid>
              <Grid container spacing={3} alignItems="center">
                {/* Switch y Autocomplete a la izquierda */}
                <Grid item xs={12} sm={8} container alignItems="center" spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      control={
                        <Switch checked={showMore} onChange={(e) => handleConTermino(e.target.checked)} />
                      }
                      label="Sin seguimiento"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AutocompleteSelect
                      data={dataUserPermisos}
                      label="Perfil de usuario"
                      value={showUser}
                      name="showUser"
                      OptionLabel="UsuarioPropietario"
                      onChange={(selectedOption) => setShowUser(selectedOption)}
                    />
                  </Grid>
                </Grid>

                {/* Botones a la derecha */}
                <Grid item xs={12} sm={4} container justifyContent="flex-end" spacing={2}>
                  {middleware.checkMenuActionId('Agregar') && (
                    <Button
                      variant="outlined"
                      color="primaryDark"
                      startIcon={<Icon>add_box</Icon>}
                      size="small"
                      onClick={() => navigate('/oficialia-captura')}
                      sx={{ borderRadius: '10px' }}
                    >
                      Nuevo
                    </Button>
                  )}
                  {middleware.checkMenuActionId('Exportar') && (
                    <Button
                      variant="outlined"
                      color="primaryDark"
                      startIcon={<Icon>download</Icon>}
                      size="small"
                      onClick={handleExport}
                      sx={{ borderRadius: '10px', ml: 1 }}
                    >
                      Exportar
                    </Button>
                  )}
                </Grid>
              </Grid>

              {/* Tabla */}
              <Grid item xs={12} sx={{ mt: 2 }}>
                {showMessage && (
                  <Box marginTop={2}>
                    <MessageAlert
                      showMessage={showMessage}
                      successDownload={successDownload}
                      setShowMessage={setShowMessage}
                    />
                  </Box>
                )}
                {isDownloading && <Download isDownload={isDownloading} format="xlsx" />}
                <CustomTableOficios
                  rows={data}
                  columns={columns}
                  total={dataTotal}
                  page={params.page}
                  pageSize={params.pageSize}
                  isLoading={isLoading}
                  handlePagination={handlePagination}
                  handleSort={handleSort}
                />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Container>
    </AdminLayout>
  );
};
export default OficiliaPartes;
