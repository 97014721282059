import { ProtectedRoute } from '@middlewares';
import OficiliaPartes from '../../pages/Oficialia/OficiliaPartes';
import OficiliaFolios from '../../pages/Oficialia/OficiliaFolios';
import OficiliaTransito from '../../pages/Oficialia/OficiliaTransito';
import CapturaOficio from '../../pages/Oficialia/CapturaOficio';
import VerOficio from '../../pages/Oficialia/VerOficio';
import RedactarOficios from '../../pages/Oficialia/RedactarOficios';

const OficiliaRoutes = [
  {
    path: '/oficialia-folios',
    element: (
      <ProtectedRoute>
        <OficiliaFolios />
      </ProtectedRoute>
    ),
  },
  {
    path: '/oficialia-redactar',
    element: (
      <ProtectedRoute>
        <RedactarOficios />
      </ProtectedRoute>
    ),
  },
  {
    path: '/oficialia-de-partes',
    element: (
      <ProtectedRoute>
        <OficiliaPartes />
      </ProtectedRoute>
    ),
  },
  {
    path: '/oficialia-en-transito/:id',
    element: (
      <ProtectedRoute>
        <OficiliaTransito />
      </ProtectedRoute>
    ),
  },
  {
    path: '/oficialia-captura',
    element: (
      <ProtectedRoute>
        <CapturaOficio />
      </ProtectedRoute>
    ),
  },
  {
    path: '/oficialia-recibir',
    element: (
      <ProtectedRoute>
        <CapturaOficio />
      </ProtectedRoute>
    ),
  },
  {
    path: '/oficialia-ver/:id',
    element: (
      <ProtectedRoute>
        <VerOficio />
      </ProtectedRoute>
    ),
  },
];

export default OficiliaRoutes;
