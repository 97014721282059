import React, { useState } from 'react';
import { TextField, Button, Grid, Modal, Box, Typography } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Document, Packer, Paragraph, TextRun, AlignmentType } from 'docx';
import { saveAs } from 'file-saver';
import './editor.css';

const EditorDocumento = () => {
  const [documentData, setDocumentData] = useState({
    paraQuien: '',
    cargo: '',
    dependencia: '',
    lugar: '',
    fecha: '',
    asunto: '',
    numOficio: '',
    contenido: '',
  });
  const [previewOpen, setPreviewOpen] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDocumentData((prev) => ({ ...prev, [name]: value }));
  };

  const handleEditorChange = (content) => {
    setDocumentData((prev) => ({ ...prev, contenido: content }));
  };

  const handlePreview = () => {
    setPreviewOpen(true);
  };

  const handleExport = async () => {
    const doc = new Document({
      sections: [
        {
          properties: {},
          children: [
            new Paragraph({
              alignment: AlignmentType.RIGHT,
              children: [new TextRun(`${documentData.lugar}, a ${documentData.fecha}`)],
            }),
            new Paragraph({
              spacing: { before: 200, after: 200 },
              children: [
                new TextRun({
                  text: `Oficio ${documentData.numOficio}`,
                  bold: true,
                }),
              ],
            }),
            new Paragraph({
              spacing: { after: 200 },
              children: [
                new TextRun({
                  text: `Asunto: ${documentData.asunto}`,
                  bold: true,
                }),
              ],
            }),
            new Paragraph({
              children: [new TextRun(documentData.contenido)],
            }),
            new Paragraph({
              spacing: { before: 400 },
              alignment: AlignmentType.CENTER,
              children: [new TextRun('Atentamente')],
            }),
            new Paragraph({
              spacing: { before: 200 },
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun(documentData.paraQuien),
                new TextRun({
                  text: documentData.cargo,
                  break: 1,
                }),
                new TextRun(documentData.dependencia),
              ],
            }),
          ],
        },
      ],
    });

    const blob = await Packer.toBlob(doc);
    saveAs(blob, 'Oficio.docx');
  };

  return (
    <Box p={2}>
      <Typography variant="h4" gutterBottom>
        Redactar Oficio
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Para Quien"
            name="paraQuien"
            value={documentData.paraQuien}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Cargo"
            name="cargo"
            value={documentData.cargo}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Dependencia"
            name="dependencia"
            value={documentData.dependencia}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Lugar"
            name="lugar"
            value={documentData.lugar}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Fecha"
            type="date"
            name="fecha"
            InputLabelProps={{ shrink: true }}
            value={documentData.fecha}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Número de Oficio"
            name="numOficio"
            value={documentData.numOficio}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Asunto"
            name="asunto"
            value={documentData.asunto}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          <ReactQuill
            theme="snow"
            value={documentData.contenido}
            onChange={handleEditorChange}
            style={{ height: '200px' }}
          />
        </Grid>
      </Grid>
      <Box mt={3}>
        <Button variant="contained" color="primary" onClick={handlePreview} style={{ marginRight: 8 }}>
          Preview
        </Button>
        <Button variant="contained" color="secondary" onClick={handleExport}>
          Exportar DOCX
        </Button>
      </Box>

      <Modal open={previewOpen} onClose={() => setPreviewOpen(false)}>
        <Box
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            backgroundColor: 'white',
            padding: '16px',
            boxShadow: 24,
          }}
        >
          <Typography variant="h6">Preview</Typography>
          <Box mt={2}>
            <Typography align="right">{`${documentData.lugar}, a ${documentData.fecha}`}</Typography>
            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
              {`Oficio ${documentData.numOficio}`}
            </Typography>
            <Typography variant="subtitle1" style={{ fontWeight: 'bold', marginBottom: '16px' }}>
              {`Asunto: ${documentData.asunto}`}
            </Typography>
            <Box dangerouslySetInnerHTML={{ __html: documentData.contenido }} />

            <Box mt={4} align="center">
              <Typography>Atentamente,</Typography>
              <Typography>{documentData.paraQuien}</Typography>
              <Typography>{documentData.cargo}</Typography>
              <Typography>{documentData.dependencia}</Typography>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default EditorDocumento;
