import ApiExec, { downloadFile, uploadFiles } from '@utils/ApiExec';

class OficialiaServices {
  getOficialia = (params) => {
    return ApiExec(params, 'oficialia/get-oficialia', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getDirectorioRecursivo = (params) => {
    return ApiExec(params, 'oficialia/get-recursive-directory', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getOficioRecibeHistory = (params) => {
    return ApiExec(params, 'oficialia/get-oficio-recibe_history', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  setOficioReenviar = (params) => {
    return ApiExec(params, 'oficialia/set-oficio-reenviar', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getStatsEnTransito = (params) => {
    return ApiExec(params, 'oficialia/get-stats-en-transito', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  aceptarOficiosEnTransito = (params) => {
    return ApiExec(params, 'oficialia/aceptar-oficios-en-transito', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getPublicFile = (params) => {
    return ApiExec(params, 'oficialia/get-PublicFile', 'GET')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getOficiosSeguimiento = (params) => {
    return ApiExec(params, 'oficialia/get-oficios-seguimiento', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getPermisosPersonasOficialia = (params) => {
    return ApiExec(params, 'oficialia/get-permisos-personas', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  setSeguimientoOficio = (params) => {
    return uploadFiles(params, 'oficialia/set-seguimiento-oficio', 'POST', false)
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  AsignarFolioOficio = (params) => {
    return ApiExec(params, 'oficialia/get-folio-interno', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getFoliosPermisos = (params) => {
    return ApiExec(params, 'oficialia/get-folio-permisos', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getOficialiaFolios = (params) => {
    return ApiExec(params, 'oficialia/oficialia-folios', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getOficialiaFoliosHistory = (params) => {
    return ApiExec(params, 'oficialia/get-folio-interno-history', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getStatusOficio = (params) => {
    return ApiExec(params, 'oficialia/get-status-oficios', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getOficiosEnTransito = (params) => {
    return ApiExec(params, 'oficialia/get-oficios-en-transito', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  cancelOficiosEnTransito = (params) => {
    return ApiExec(params, 'oficialia/cancel-oficios-en-transito', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  setOficialia = (params) => {
    return uploadFiles(params, 'oficialia/set-oficialia', 'POST', false)
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getDocumentosAdjuntos = (params) => {
    return ApiExec(params, 'oficialia/get-documentos-adjuntos', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getOficialiaTipoDoctos = (params) => {
    return ApiExec(params, 'oficialia/get-oficialia-tipo-docto', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getOficialiaEmpresas = (params) => {
    return ApiExec(params, 'oficialia/get-oficialia-empresas', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getOficialiaDirectorioEmpresas = (params) => {
    return ApiExec(params, 'oficialia/get-oficialia-directorio-empresas', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  insertOficialiaEmpresa = (params) => {
    return ApiExec(params, 'oficialia/insert-oficialia-empresa', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  insertOficialiaDirectorioEmpresa = (params) => {
    return ApiExec(params, 'oficialia/insert-oficialia-directorio-empresa', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getOficialiaTotales = (params) => {
    return ApiExec(params, 'oficialia/get-oficialia-totales', 'POST')
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  exportOficialia = (params) => {
    return downloadFile({
      api: 'oficialia/export-oficialia',
      method: 'POST',
      data: params,
    })
      .then((res) => res)
      .then((res) => res)
      .catch((e) => e);
  };
}

const oficialia = new OficialiaServices();
export default oficialia;
