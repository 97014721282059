import React from 'react';
import { Autocomplete, TextField, Box } from '@mui/material';

const AutocompleteGenerico = ({ data, label, value, name, onChange, OptionLabel, error, helperText }) => {
  return (
    <Box>
      {/* Autocomplete para seleccionar prioridad */}
      <Autocomplete
        value={value || null}
        name={name}
        onChange={(event, newValue) => {
          if (newValue) onChange(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          console.log('=<newInputValue: ', newInputValue);
          if (newInputValue === '') {
            return;
          }
        }}
        options={data}
        getOptionLabel={(option) =>
          typeof option === 'object' && option[OptionLabel] ? option[OptionLabel] : ''
        }
        isOptionEqualToValue={(option, value) => option[OptionLabel] === value[OptionLabel]}
        renderInput={(params) => (
          <TextField {...params} label={label} error={error} helperText={helperText} fullWidth />
        )}
        fullWidth
      />
    </Box>
  );
};

export default AutocompleteGenerico;
