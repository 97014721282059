import { useRef, useEffect, useState } from 'react';
import { QRCode } from 'react-qrcode-logo';

// Material UI
import { Container, Grid, Box, Typography, Paper, Button } from '@mui/material';
import { DownloadForOffline } from '@mui/icons-material';
import useWindowDimensions from '@hooks/useWindowDimensions';

function QRPreregistro(props) {
  const { dataQR, mensaje, dataEvent } = props;
  const { width } = useWindowDimensions();

  const qrRef = useRef();
  const [imageSrc, setImageSrc] = useState(null); // Para guardar la imagen generada

  // URL de la imagen de fondo (puede ser cualquier imagen accesible públicamente)
  const backgroundImageUrl = dataEvent.plantillaURL;

  // Función para generar la imagen con el QR encima de la imagen de fondo
  const generateImage = () => {
    const qrCanvas = qrRef.current.querySelector('canvas'); // Seleccionamos el canvas del QR
    if (qrCanvas) {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      if (backgroundImageUrl) {
        const backgroundImage = new Image();
        backgroundImage.crossOrigin = 'Anonymous'; // Esto permite la carga cruzada si el servidor lo permite
        backgroundImage.src = backgroundImageUrl;

        // Esperamos que la imagen de fondo se haya cargado
        backgroundImage.onload = () => {
          // Establecemos el tamaño del canvas según el tamaño de la imagen de fondo
          canvas.width = backgroundImage.width;
          canvas.height = backgroundImage.height;

          // Dibujamos la imagen de fondo en el canvas
          ctx.drawImage(backgroundImage, 0, 0, backgroundImage.width, backgroundImage.height);

          // Ahora dibujamos el QR sobre la imagen de fondo
          const qrImage = qrCanvas.toDataURL('image/png'); // Obtenemos el QR en formato PNG
          const qrImg = new Image();
          qrImg.src = qrImage;

          // Esperamos a que el QR se haya cargado antes de dibujarlo sobre el fondo
          qrImg.onload = () => {
            // Dibujamos el QR en el canvas encima de la imagen de fondo
            const qrSize = 300; // Tamaño del QR
            const xPos = (canvas.width - qrSize) / 2; // Posición horizontal centrada
            const yPos = (canvas.height - qrSize) / 2 + 555; // Mueve el QR 100 píxeles hacia abajo

            // Dibujamos el QR
            ctx.drawImage(qrImg, xPos, yPos, qrSize, qrSize);

            // Ahora que todo está dibujado, generamos la imagen y la mostramos en el componente
            setImageSrc(canvas.toDataURL('image/png')); // Guardamos la imagen generada en el estado
          };
        };
      } else {
        // Si no hay imagen de fondo, solo renderizamos el QR
        const qrImage = qrCanvas.toDataURL('image/png');
        setImageSrc(qrImage);
      }
    }
  };

  // Llamar a generateImage cuando el componente se monte
  useEffect(() => {
    if (dataQR) {
      generateImage();
    }
  }, [dataQR]);

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = imageSrc;
    link.download = `QR_${dataQR.NombreCompleto}.png`; // Nombre del archivo de la imagen
    link.click(); // Simulamos el clic para descargar la imagen
  };

  return (
    <Box component="section" pt={6} my={6} sx={{ alignItems: 'center' }}>
      <Container component="main" maxWidth="md">
        <Paper
          elevation={4}
          alignItems="center"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 2,
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
            <Typography sx={{ fontSize: { xs: 18, md: 22 }, color: '#00a152', fontWeight: 600 }}>
              {mensaje}
            </Typography>
          </Box>

          {dataQR && (
            <div
              style={{
                width: '100%',
                height: '100%',
                marginTop: 50,
                textAlign: 'center',
              }}
              ref={qrRef}
            >
              <Grid container justifyContent="center" alignItems="center">
                {/*       <Grid item xs={12}>
                  <Typography sx={{ color: "#1769aa", fontSize: 22, fontWeight: 600 }} gutterBottom>
                    Pase Personal
                  </Typography>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={handleDownload}
                    endIcon={<DownloadForOffline />}
                  >
                    Descargar QR
                  </Button>
                  <br />
                </Grid> */}
                {/*     <Grid item xs={12}>
                  <Box sx={{ display: 'none' }}>
                    <QRCode
                      value={dataQR.UUID}
                      fgColor="black" // Color del código QR (primer plano)
                      bgColor="white" // Color de fondo del QR (segundo plano)
                      style={{ marginTop: '50px' }} // Puedes agregar estilo adicional si lo necesitas
                    />
                  </Box>
                </Grid> */}
                {/*  <Grid item xs={12}>
                  <br />
                  <br />
                  <Typography sx={{ color: '#1769aa', fontSize: 26, fontWeight: 600 }} gutterBottom>
                    {dataQR.NombreCompleto}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Folio: <strong>{dataQR.Folio}</strong>
                  </Typography>
                </Grid> */}
              </Grid>

              {/* Mostrar la imagen combinada con QR o solo el QR */}
              {/*     {imageSrc && (
                <img
                  src={imageSrc}
                  alt="Imagen con QR"
                  style={{
                    marginTop: '20px',
                    width: width <= 600 && backgroundImageUrl ? '100%' : backgroundImageUrl ? '60%' : '45%',
                  }}
                />
              )} */}
            </div>
          )}
        </Paper>
      </Container>

      {/*   <Box alignItems="center">
        {dataQR && imageSrc && (
          <Grid container justifyContent="center" alignItems="center">
            <Button
              variant="contained"
              size="small"
              onClick={handleDownload}
              endIcon={<DownloadForOffline />}
            >
              Descargar QR
            </Button>
          </Grid>
        )}
      </Box> */}
    </Box>
  );
}

export default QRPreregistro;
