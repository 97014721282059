import React, { useEffect, useState } from 'react';
import AdminLayout from '@components/MainPage/AdminLayout';
import {
  Card,
  CardContent,
  Container,
  Button,
  Grid,
  Box,
  Icon,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CustomTableOficios from '@components/Tables/CustomTableOficios';
import AutocompleteGnerico from '@components/Oficialia/Autocomplete';
import { getVars } from '@utils/global';
import dayjs from 'dayjs';
import OficialiaServices from '@services/OficialiaServices';
import Swal from 'sweetalert2';
import FilterOficialia from '@components/Oficialia/Filter';
import middleware from '@middlewares/middleware';
import Download from '@components/Downloads/Download';
import MessageAlert from '@components/Downloads/MessageAlert';
import CardStatsOficialia from '@components/Card/CardStatsOficialia';

import './OficialiaPartes.css';

const OficiliaFolios = () => {
  const { user } = getVars('Token');
  const navigate = useNavigate();
  const [statsOficios, setStatsOficios] = useState({
    EnTransito: 0,
    TotalOficios: 0,
    TotalOficiosActivos: 0,
    OficiosExpirados: 0,
    ProximosAExpirar: 0,
  });
  const [data, setData] = useState([]);
  const [dataFolios, setDataFolios] = useState([]);
  const [dataTotal, setDataTotal] = useState(0);
  const [params, setParams] = useState({
    page: 0,
    pageSize: 10,
    filtered: middleware.checkMenuActionId('admin')
      ? []
      : [
          {
            id: ` and o.id in (select idOficio from cde_oficialia_recepcion where idEstatus=1 and idUsuarioRecibe=${user.id} and FechaElimino is null)`,
            filterQuery: true,
          },
        ],
    sorted: [{ id: 'o.FechaRecibido', value: 'DESC' }],
  });

  const [isLoading, setLoading] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [successDownload, setSuccessDownload] = useState(false);
  const [errors, setErrors] = useState({ Folio: null, Asunto: null });
  const [state, setState] = useState({
    Folio: {
      Anio: null,
      Folio: null,
      Nomenclatura: '',
      id: null,
      idEmpresaArea: null,
    },
  });

  useEffect(() => {
    if (state.Folio.id) {
      getDataHistory();
    }
  }, [state.Folio]);

  useEffect(() => {
    getStatsTransito();
  }, []);

  const columns = [
    //{ id: "row1", label: "Numeros", type: "number", fixed: 2 },
    { id: 'Fecha', label: 'Fecha', type: 'html', minWidth: 100, maxWidth: 180 },
    {
      id: 'Folio',
      label: 'Folio',
      type: 'html',
      icon: 'reply',
      iconColor: '#000F9F',
      iconSize: 20,
      minWidth: 150,
      maxWidth: 400,
    },
    { id: 'Asunto', label: 'asunto' },
  ];

  const getData = async () => {
    try {
      setLoading(true);
      const res = await OficialiaServices.getFoliosPermisos({
        Anio: dayjs().year(),
        page: 0,
        pageSize: 100,
        filtered: [
          { id: 'Anio', value: dayjs().year(), filter: '=' },
          {
            id: ` and idEmpresaArea in (select idEmpresaArea from cde_oficialia_permisos where idUsuarioPermitido=${user.id} and Foliar=1)`,
            filterQuery: true,
          },
        ],
      });

      const { results, response } = res;
      if (results) {
        setDataFolios(response.data);
        if (response.data.length === 1) setState({ ...state, Folio: response.data[0] });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Swal.fire({ title: error.message, icon: 'warning' });
    }
  };

  const getDataHistory = async () => {
    try {
      setLoading(true);

      const resHistory = await OficialiaServices.getOficialiaFoliosHistory({
        idUsuarioPermitido: user.id,
        idEmpresaArea: state.Folio.idEmpresaArea,
        page: 0,
        pageSize: 25,
        filtered: [],
        sorted: [{ id: 'fu.FechaCreo', value: 'desc' }],
      });

      const { results, response } = resHistory;

      if (results) {
        setData(response.data);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      Swal.fire({ title: error.message, icon: 'warning' });
    }
  };

  useEffect(() => {
    getData();
    // getTotales();
  }, [params]);

  const getStatsTransito = async () => {
    try {
      setLoading(true);
      const res = await OficialiaServices.getStatsEnTransito({
        page: 0,
        pageSize: 99999,
        filtered: middleware.checkMenuActionId('admin')
          ? [
              {
                id: 'r.idEstatus',
                value: 1,
                filter: '=',
              },
            ]
          : [
              {
                id: ` and r.idEstatus=1 and ( r.idParaQuien = ${user.id} or r.idDeQuien = ${user.id})`,
                filterQuery: true,
              },
            ],
      });
      const { results, response, message } = res;

      if (results) {
        setStatsOficios(response.data);
      } else {
        setStatsOficios({
          EnTransito: 0,
          TotalOficios: 0,
          TotalOficiosActivos: 0,
          OficiosExpirados: 0,
          ProximosAExpirar: 0,
        });
        Swal.fire({ title: message, icon: 'warning' });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Swal.fire({ title: error.message, icon: 'warning' });
    }
  };

  const handlePagination = ({ page, pageSize }) => {
    const params = {
      ...params,
      page: page,
      pageSize: pageSize,
    };
    setParams(params);
  };

  const handleSort = (e) => {
    /*       setOrderBy(e.orderBy);
        setOrder(e.order); */

    var OrdandoPor = [];

    if (e.orderBy === 'DeQuien') {
      OrdandoPor.push({ id: 'de.NombreCompleto', value: e.order });
    }

    if (e.orderBy === 'ParaQuien') {
      OrdandoPor.push({ id: 'dr.NombreCompleto', value: e.order });
    }

    if (e.orderBy === 'Fecha') {
      OrdandoPor.push({ id: 'o.FechaOficio', value: e.order });
    }

    if (e.orderBy === 'Oficio') {
      OrdandoPor.push({ id: 'o.Asunto', value: e.order });
    }

    const paramsNew = {
      ...params,
      sorted: OrdandoPor,
    };

    setParams(paramsNew);
  };

  const handleExport = () => {
    setIsDownloading(true);
    OficialiaServices.exportMecanismos({
      page: 0,
      pageSize: 99999999999999,
      filtered: params.filtered,
    })
      .then((res) => {
        if (res.success) {
          setShowMessage(true);
          setSuccessDownload(true);
        } else {
          setShowMessage(true);
          setSuccessDownload(false);
        }
      })
      .catch((error) => {
        setShowMessage(true);
        setSuccessDownload(false);
      })
      .finally(() => setIsDownloading(false));
  };

  const handleConTermino = (e) => {
    setShowMore(e);
  };

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <Grid container spacing={2}>
          {/* <Grid item xs={2}>
						<CardStatsOficialia
							icon={"fact_check"}
							title={"Turnados hoy"}
							//total={`${totalUnicosSegmento}`}
							total={`${1} de ${2}`}
							// subtitle={"Únicos de General"}
							loading={isLoading}
						/>
					</Grid> */}

          <Grid item xs={2}>
            <CardStatsOficialia
              icon={'lightbulb'}
              iconColor={'#DC0019'}
              title={'Expiran'}
              //total={`${totalUnicosSegmento}`}
              total={`${parseInt(statsOficios.OficiosExpirados)} de ${
                parseInt(statsOficios.OficiosExpirados) + parseInt(statsOficios.ProximosAExpirar)
              }`}
              subtitle={`expirados`}
              loading={isLoading}
            />
          </Grid>
          <Grid item xs={2}>
            <CardStatsOficialia
              icon={'done_all'}
              iconColor={'#000F9F'}
              title={'Oficios'}
              //total={`${totalUnicosSegmento}`}
              total={`${
                parseInt(statsOficios.TotalOficios) - parseInt(statsOficios.TotalOficiosActivos)
              } de ${parseInt(statsOficios.TotalOficios)}`}
              subtitle={'concluidos'}
              loading={isLoading}
            />
          </Grid>

          <Grid item xs={2}>
            <CardStatsOficialia
              icon={'repeat'}
              iconColor={'#000F9F'}
              title={'En tránsito'}
              //total={`${totalUnicosSegmento}`}
              total={`${parseInt(statsOficios.EnTransito)}`}
              // subtitle={"Únicos de General"}
              loading={isLoading}
              onClick={() => navigate(`/oficialia-en-transito`)}
            />
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FilterOficialia
                      setFiltros={(e) => {
                        setParams({ ...params, page: 0, filtered: e });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {showMessage && (
                      <Box marginTop={2}>
                        <MessageAlert
                          showMessage={showMessage}
                          successDownload={successDownload}
                          setShowMessage={setShowMessage}
                        />
                      </Box>
                    )}
                    {isDownloading && <Download isDownload={isDownloading} format="xlsx" />}
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      <FormControlLabel
                        control={
                          <Switch checked={showMore} onChange={(e) => handleConTermino(e.target.checked)} />
                        }
                        label="Con término"
                      />
                      <AutocompleteGnerico
                        data={dataFolios}
                        name="Folio"
                        OptionLabel="Nomenclatura"
                        label="Folios"
                        value={state}
                        onChange={setState}
                        error={!!errors.Folio} // Indica si hay un error
                        helperText={errors.Folio} // Muestra el mensaje de error
                      />
                      <Box sx={{ marginLeft: 'auto' }}>
                        {' '}
                        {/* Esto empuja los botones a la derecha */}
                        {middleware.checkMenuActionId('Agregar') && (
                          <>
                            {isDownloading ? (
                              <></>
                            ) : (
                              <Button
                                variant="outlined"
                                color="primaryDark"
                                startIcon={<Icon>add_box</Icon>}
                                size="small"
                                onClick={() => navigate('/oficialia-captura')}
                                sx={{ borderRadius: '10px', ml: 1 }} // Añade margen entre botones
                              >
                                Nuevo
                              </Button>
                            )}
                          </>
                        )}
                        {middleware.checkMenuActionId('Exportar') && (
                          <>
                            {isDownloading ? (
                              <></>
                            ) : (
                              <Button
                                variant="outlined"
                                color="primaryDark"
                                startIcon={<Icon>download</Icon>}
                                size="small"
                                onClick={handleExport}
                                sx={{ borderRadius: '10px', ml: 1 }} // Añade margen entre botones
                              >
                                Exportar
                              </Button>
                            )}
                          </>
                        )}
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    {/* <DataGridTmp data={data} /> */}
                    <CustomTableOficios
                      rows={data}
                      columns={columns}
                      total={dataTotal}
                      page={params.page}
                      pageSize={params.pageSize}
                      isLoading={isLoading}
                      handlePagination={handlePagination}
                      handleSort={handleSort}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </AdminLayout>
  );
};
export default OficiliaFolios;
