import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText, Box } from '@mui/material';

const AutocompleteSelect = ({ data, label, value, name, onChange, OptionLabel, error, helperText }) => {
  return (
    <Box>
      <FormControl fullWidth error={error}>
        <InputLabel>{label}</InputLabel>
        <Select
          value={value && value[OptionLabel] ? value[OptionLabel] : ''} // Verifica que OptionLabel exista en value
          name={name}
          onChange={(event) => {
            const selectedOption = data.find((option) => option[OptionLabel] === event.target.value);
            onChange(selectedOption); // Retorna el objeto completo
          }}
        >
          {data.map((option, index) => (
            <MenuItem key={index} value={option[OptionLabel]}>
              {option[OptionLabel]}
            </MenuItem>
          ))}
        </Select>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </Box>
  );
};

export default AutocompleteSelect;
