import React from 'react';
import { Container, Grid } from '@mui/material';
import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import { useNavigate } from 'react-router-dom';
import { getVars } from '@utils/global';
import EditorDocumento from '@components/Oficialia/EditorDocumento';
const RedactarOficios = () => {
  const { user } = getVars('Token');
  const navigate = useNavigate();

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <ActionHeader
          title="Redactar oficios"
          isAction={true}
          titleButton="Regresar"
          iconButton="arrow_back"
          handleclick={() => navigate(-1)}
        />
        <Grid container spacing={2}>
          <EditorDocumento />
        </Grid>
      </Container>
    </AdminLayout>
  );
};

export default RedactarOficios;
