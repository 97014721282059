import { useEffect, useState, useRef, useCallback } from 'react';

// @mui material components
import {
  Typography,
  Box,
  Button,
  Stack,
  TextField,
  LinearProgress,
  Paper,
  AppBar,
  Toolbar,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import Container from '@mui/material/Container';
import { useFormik } from 'formik';
import SearchIcon from '@mui/icons-material/Search';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { CompromisoAddInterfacePublic } from '@data/interfaces/CompromisoInterfaces';
import { CompromisoAddPublicSchema } from '@data/schemas/CompromisoSchemas';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import CatalogServices from '@services/CatalogServices';
import { isNullOrUndefined, isEmptyOrInvalidString, isEmptyOrNullObject } from '@utils/validations';
import { validaINE, handleAttrs } from '@utils/Utilities';
import UserForm from '../../components/Compromisos/UserForm';
import AddressForm from '@components/Compromisos/AddressForm';
import PersonalInformationForm from '@components/Compromisos/PersonalInformationForm';
import ContactInformationForm from '@components/Compromisos/ContactInformationForm';
import events from '../../services/EventsServices';
import parse from 'html-react-parser';
import HomeIcon from '@mui/icons-material/Home';
import { QRCode } from 'react-qrcode-logo';
import Pdf from 'react-to-pdf';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { RECAPTCHA_SITE_KEY } from '@utils/global';

const Pregistration = () => {
  const { id } = useParams();
  const pdfRef = useRef();
  const [dataEvent, setDataEvent] = useState({});
  const [flagAccount, setFlagAccount] = useState(false);
  const [datauser, setDataUser] = useState({});
  const [dataQR, setDataQR] = useState({});
  const [mensaje, setMensaje] = useState('');
  const [flagNot, setFlagNot] = useState(false);
  const [flagQR, setFlagQR] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingIne, setLoadingIne] = useState(false);
  const [load, setLoad] = useState(true);
  const [loadingRegister, setLoadingRegister] = useState(false);
  const [LoadingForm, setLoadingForm] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const catalogsOptions = [
    { id: 'entidades' },
    { id: 'municipios' },
    { id: 'tipo_vialidad' },
    { id: 'medios_contacto' },
  ];
  const [catalogsFiltered, setCatalogFiltered] = useState(() => {
    let newObject = {};
    for (const item of catalogsOptions) {
      newObject[item.id] = [];
    }
    return newObject;
  });

  const formik = useFormik({
    initialValues: CompromisoAddInterfacePublic,
    validationSchema: CompromisoAddPublicSchema,
    onSubmit: (values) => {
      onChangeCaptcha(handleAttrs(values));
    },
  });

  const onChangeCaptcha = useCallback(
    (values) => {
      if (!executeRecaptcha) {
        return;
      }

      executeRecaptcha('eventos')
        .then((token) => {
          const data = {
            ...values,
            token: token,
          };
          handleVerification(handleAttrs(data));
        })
        .catch((error) => Swal.fire({ title: error, icon: 'warning' }));
    },
    // eslint-disable-next-line
    [executeRecaptcha]
  );

  useEffect(() => {
    events
      .getPublicEvent({ uuid: id })
      .then((res) => {
        if (res.results) {
          formik.setFieldValue('idUsuarioPropietario', res.response.data.idUsuarioCreo, false);
          formik.setFieldValue('idEvento', res.response.data.id, false);
          formik.setFieldValue('Password', 'C01202**', false);
          formik.setFieldValue('Confirm', 'C01202**', false);
          formik.setFieldValue('PIN', '12345', false);

          setDataEvent(res.response.data);
        } else {
          Swal.fire({
            title: res.message,
            icon: 'warning',
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
        }
      })
      .catch((error) =>
        Swal.fire({
          title: error.message,
          icon: 'warning',
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
        })
      )
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, [flagQR]);

  useEffect(() => {
    getCatalogs();
    // eslint-disable-next-line
  }, []);

  const getCatalogs = () => {
    CatalogServices.getCatalogsPublicEvents({
      catalogs: catalogsOptions,
    })
      .then((res) => {
        if (res.results) {
          setCatalogFiltered(() => {
            let newObject = {};
            for (const item of catalogsOptions) {
              newObject[item.id] = res.response.catalogs[item.id];
            }
            return newObject;
          });
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((error) => Swal.fire({ title: error.message, icon: 'warning' }))
      .finally(() => setLoad(false));
  };

  const searchINE = () => {
    if (formik.values.INE.length !== 18) {
      formik.handleChange({
        target: {
          name: 'INE',
          value: formik.values.INE,
        },
      });
      return false;
    }
    if (loadingIne) {
      return false;
    }
    if (formik.errors.INE) {
      return false;
    }

    setLoadingIne(true);
    setFlagAccount(false);
    setDataUser({});
    events
      .publicGetUser({ INE: formik.values.INE, idEvento: formik.values.idEvento })
      .then((res) => {
        if (res.results) {
          setFlagAccount(true);
          setDataUser(res.response.data);
        } else {
          setFlagAccount(false);
          setDataUser();
        }
      })
      .catch((error) => {
        setFlagAccount(false);
        setDataUser();
        Swal.fire({ title: error.message, icon: 'warning' });
      })
      .finally(() => setLoadingIne(false));
  };

  const RegisterEvent = () => {
    if (!datauser.id) {
      return true;
    }
    if (loadingRegister) {
      return false;
    }

    setLoadingRegister(true);
    events
      .publicSetGuestEvent({
        idUsuarioInvitado: datauser.id,
        idEvento: dataEvent.id,
      })
      .then((res) => {
        if (res.results) {
          setFlagQR(true);
          setMensaje(res.message);
          setDataQR(res.response.data);
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((error) => Swal.fire({ title: error.message, icon: 'warning' }))
      .finally(() => setLoadingRegister(false));
  };

  const onblurINE = (INEValue) => {
    setFlagAccount(false);
    setDataUser({ Nombre: '' });
    let genderId = '';
    let stateId = '';
    let dob = '';
    let len = INEValue.length;

    if (len >= 12) {
      let year = moment().format('YYYY');
      let dobAux = INEValue.substring(6, 12);
      let anio = dobAux.substring(0, 2);
      let mes = dobAux.substring(2, 4);
      let dia = dobAux.substring(4, 6);

      anio = moment(anio, 'YY').format('YYYY');

      let diff = parseInt(year) - parseInt(anio);

      if (diff > 0) {
        dob = `${dia}/${mes}/${anio}`;
      } else {
        anio = anio - 100;
        dob = `${dia}/${mes}/${anio}`;
      }
    }
    if (len >= 14) {
      let _sId = parseInt(INEValue.substring(12, 14));
      stateId = catalogsFiltered.entidades.find((item) => item.value === _sId) ? _sId : '';
    }
    if (len >= 15) {
      const genderKey = INEValue.substring(14, 15);
      genderId = genderKey === 'M' ? 3 : genderKey === 'H' ? 2 : 1;
    }

    formik.setValues({
      ...formik.values,
      FechaNacimiento: moment(dob, 'DD/MM/YYYY'),
      idEstadoNacimiento: stateId,
      idSexo: genderId,
    });
  };

  const onChange = (e) => {
    setFlagAccount(false);
    setDataUser({ message: '' });
    formik.handleChange(e);
  };

  const handleVerification = (values) => {
    if (!isEmptyOrNullObject(values)) {
      const res = validaINE(
        values.INE,
        values.Nombre,
        values.Paterno,
        values.Materno,
        values.idSexo,
        values.FechaNacimiento,
        values.idEstadoNacimiento
      );

      if (!res.results) {
        Swal.fire({
          title: `¡Atención!`,
          text: res.message,
          icon: 'info',
          reverseButtons: true,
          showCancelButton: false,
          confirmButtonText: 'Ok',
        });
      } else {
        handleSave(values);
      }
    }
  };

  const handleSave = (values) => {
    if (LoadingForm) {
      return false;
    }

    setLoadingForm(true);
    let data = {};

    Object.keys(CompromisoAddInterfacePublic).forEach((key) => {
      data[key] = values[key] !== null ? values[key] : CompromisoAddInterfacePublic[key];
    });

    data = {
      ...data,
      VigenciaINE: values.VigenciaINE ? parseInt(values.VigenciaINE) : null,
      INE: values.INE.toUpperCase(),
      Nombre: values.Nombre.toUpperCase(),
      Paterno: values.Paterno.toUpperCase(),
      Materno: values.Materno.toUpperCase(),
      FechaNacimiento: moment(values.FechaNacimiento).format('YYYY-MM-DD'),
      CalleVive: `${values.TipoVialidad} ${values.CalleVive}`,
    };
    delete data.TipoVialidad;
    delete data.isUsuario;
    data = {
      ...data,
      Username: values.Celular,
      medioConfirmacion: 'sms',
      idEstructuraSocial: 1,
    };
    delete data.idMedioContacto;
    delete data.Segmentos;
    delete data.TieneAuto;
    delete data.TieneExperiencia;
    delete data.AceptaCuidarOtra;

    events
      .publicRegisterGuest(data)
      .then((res) => {
        if (res.success && res.results) {
          setFlagNot(false);
          setFlagQR(true);
          setMensaje(res.message);
          setDataQR(res.response.data);
          /*   Swal.fire({
            title: res.message,
            icon: "success",
            input: "text",
            inputAttributes: {
              autocapitalize: "off",
            },
            showCancelButton: true,
            confirmButtonText: "Confirmar",
            showLoaderOnConfirm: true,
            preConfirm: (code) => {
              if (code === res.response.codigo) {
                UserServices.codeValidate({
                  code: code,
                  c: res.response.UUID,
                })
                  .then((respuesta) => {
                    if (respuesta.success) {
                      setFlagNot(false);
                      setFlagAccount(true);
                      setDataUser({
                        NombreCompleto: `${respuesta.response.Nombre} ${respuesta.response.Paterno}`,
                        id: respuesta.response.id,
                      });
                    } else {
                      Swal.showValidationMessage(
                        `Error en la validación. contacte al administrador`
                      );
                    }
                  })
                  .catch((e) => {
                    Swal.showValidationMessage(
                      `Error en la validación. contacte al administrador.`
                    );
                  });
              } else {
                Swal.showValidationMessage(`El codígo no coincide`);
              }
            },
            allowOutsideClick: () => !Swal.isLoading(),
          }); */
          clearForm();
        } else {
          Swal.fire({ title: res.message, icon: 'warning' });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: 'warning' });
      })
      .finally(() => {
        setLoadingForm(false);
      });
  };

  const clear = () => {
    setFlagNot(false);
    setFlagQR(false);
    setDataUser({ message: '' });
    setDataQR({});
    setFlagAccount(false);

    formik.resetForm({});
    formik.setFieldValue('idUsuarioPropietario', dataEvent.idUsuarioCreo, false);
    formik.setFieldValue('idMedioContacto', 2, false);
    formik.setFieldValue('idEvento', dataEvent.id, false);
  };

  const clearForm = () => {
    formik.resetForm({});
    formik.setFieldValue('idUsuarioPropietario', dataEvent.idUsuarioCreo, false);
    formik.setFieldValue('idEvento', dataEvent.id, false);
  };

  return (
    <>
      {loading ? (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      ) : (
        <Paper elevation={0}>
          <AppBar position="static" color="primary" enableColorOnDark>
            <Toolbar>
              {!flagNot && !flagQR && (
                <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                  {dataEvent ? dataEvent.Actividad : 'Eventos'}
                </Typography>
              )}
              {(flagNot || flagQR) && (
                <>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      clear();
                    }}
                    sx={{ border: 0, p: 0 }}
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    startIcon={<HomeIcon />}
                  >
                    Inicio
                  </Button>
                </>
              )}
            </Toolbar>
          </AppBar>
          {!flagNot && !flagQR && (
            <>
              <Paper align="center" maxWidth="lg" elevation={0}>
                {isNullOrUndefined(dataEvent.imgURL) && <>{parse(dataEvent.textoPreregistro)}</>}
                {!isNullOrUndefined(dataEvent.imgURL) && (
                  <img
                    crossorigin="anonymous"
                    srcSet={dataEvent.imgURL}
                    alt={dataEvent.imgURL}
                    loading="lazy"
                  />
                )}
                <Typography variant="body1" color={'primary'}>
                  Para registrarte en el evento {<strong>{`${dataEvent.Actividad}`}</strong>}, por favor
                  escribe tu clave de elector y da clic en el botón buscar
                </Typography>
                <Stack
                  sx={{ pt: 4, ml: 10, mr: 10, pb: 8 }}
                  direction="row"
                  spacing={0}
                  justifyContent="center"
                >
                  <TextField
                    required
                    /* fullWidth */
                    sx={{ width: '50%' }}
                    error={!isEmptyOrInvalidString(formik.errors.INE)}
                    helperText={formik.errors.INE && formik.errors.INE}
                    value={formik.values.INE.toUpperCase()}
                    pattern="/[A-Z]{6}[0-9]{8}[A-Z]{1}[0-9]{3}/g"
                    type="text"
                    name="INE"
                    onChange={onChange}
                    onBlur={(e) => onblurINE(e.target.value)}
                    id="outlined-required"
                    label="Clave de Elector"
                    placeholder="Clave Elector"
                  />
                  <LoadingButton
                    size="small"
                    color="primary"
                    onClick={(e) => searchINE()}
                    loading={loadingIne}
                    loadingPosition="start"
                    startIcon={<SearchIcon />}
                    variant="contained"
                  >
                    <span>Buscar</span>
                  </LoadingButton>
                </Stack>
                <br />
                {flagAccount && (
                  <>
                    <Typography variant="h6" sx={{ color: '#FE9A2E' }}>
                      HOLA {datauser.NombreCompleto}
                    </Typography>
                    <Typography> DESEAS ASISTIR AL EVENTO?, DA CLICK AL BOTON </Typography>
                    <Stack sx={{ pt: 1 }} direction="row" spacing={2} justifyContent="center">
                      <br />
                      <LoadingButton
                        size="small"
                        color="primary"
                        onClick={(e) => RegisterEvent()}
                        loading={loadingRegister}
                        loadingPosition="end"
                        endIcon={<AppRegistrationIcon />}
                        variant="contained"
                      >
                        <span>Registrarme</span>
                      </LoadingButton>
                    </Stack>
                  </>
                )}
              </Paper>
              <Paper align="center" maxWidth={'lg'} elevation={0}>
                {!flagAccount && !formik.errors.INE && isNullOrUndefined(datauser) && (
                  <Box align="center" sx={{ pt: 4, pb: 15 }}>
                    <Typography sx={{ color: '#FE9A2E' }}>
                      Esta CLAVE DE ELECTOR no está registrada.
                    </Typography>
                    <Typography sx={{ color: '#FE9A2E' }}>
                      Para tener acceso al EVENTO por favor
                      <br />
                      <Button
                        variant="contained"
                        size="medium"
                        color={'primary'}
                        onClick={(e) => setFlagNot(true)}
                      >
                        {' '}
                        REGÍSTRATE AQUÍ.
                      </Button>
                      <br />
                      Es muy fácil.
                    </Typography>
                    {/*  <Typography
                        color={"primary"}
                        onClick={(e) => setFlagNot(true)}
                      >
                        Registrate aquí
                      </Typography> */}
                  </Box>
                )}
              </Paper>
            </>
          )}
          {flagNot && (
            <Container component="main" maxWidth="lg">
              <Paper
                elevation={4}
                alignItems="center"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  p: 2,
                }}
              >
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  sx={{ minHeight: 'calc(100vh - 68px)' }}
                >
                  <Grid item xs={12}>
                    <Typography variant="h4" color={'#1769aa'} gutterBottom>
                      Para poderse registrar en {`${dataEvent.Actividad}`} es necesario proporcionar la
                      siguiente información.
                    </Typography>
                  </Grid>
                  {dataEvent.idModoEvento !== 3 && (
                    <Grid item xs={12}>
                      <UserForm
                        catalogs={catalogsFiltered}
                        loadigCatalogs={load}
                        errors={formik.errors}
                        touched={formik.touched}
                        values={formik.values}
                        setValues={formik.setValues}
                        handleChange={formik.handleChange}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <PersonalInformationForm
                      catalogs={catalogsFiltered}
                      loadigCatalogs={load}
                      errors={formik.errors}
                      touched={formik.touched}
                      values={formik.values}
                      setValues={formik.setValues}
                      handleChange={formik.handleChange}
                      NotINE={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ContactInformationForm
                      errors={formik.errors}
                      touched={formik.touched}
                      values={formik.values}
                      handleChange={formik.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <AddressForm
                      catalogs={catalogsFiltered}
                      loadigCatalogs={load}
                      errors={formik.errors}
                      touched={formik.touched}
                      values={formik.values}
                      setValues={formik.setValues}
                      handleChange={formik.handleChange}
                      movile={true}
                    />
                  </Grid>
                  <LoadingButton
                    type="submit"
                    size="small"
                    fullWidth
                    color="primary"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={formik.submitForm}
                    loading={LoadingForm}
                    variant="contained"
                  >
                    <span>Registrarme</span>
                  </LoadingButton>
                </Grid>
              </Paper>
            </Container>
          )}

          {flagQR && (
            <>
              <Container component="main" maxWidth="md">
                <Paper
                  elevation={4}
                  alignItems="center"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    p: 2,
                  }}
                >
                  <Grid
                    container
                    item
                    justifyContent="center"
                    alignItems="center"
                    //sx={{ minHeight: "calc(100vh - 68px)" }}
                  >
                    <Typography color={'#00a152'} variant="h5">{`${mensaje}`}</Typography>
                  </Grid>
                  {dataQR && (
                    <div
                      style={{
                        width: '100%',
                        height: '100%',
                        marginTop: 50,
                        textAlign: 'center',
                      }}
                      ref={pdfRef}
                    >
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        //sx={{ minHeight: "calc(100vh - 68px)" }}
                      >
                        <Grid item xs={12}>
                          <Typography variant="h4" sx={{ color: '#1769aa' }} gutterBottom>
                            Pase Personal
                          </Typography>
                          <br />
                        </Grid>
                        <Grid item xs={12}>
                          <QRCode value={dataQR.UUID} />
                        </Grid>
                        <Grid item xs={12}>
                          <br />
                          <br />
                          <Typography variant="h6" sx={{ color: '#1769aa' }} gutterBottom>
                            {dataQR.NombreCompleto}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h6" gutterBottom>
                            Folio: <strong>{dataQR.Folio}</strong>
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  )}
                </Paper>
              </Container>
              <Box alignItems="center">
                {dataQR && (
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    //sx={{ minHeight: "calc(100vh - 68px)" }}
                  >
                    <Pdf targetRef={pdfRef} filename={`Pase_${dataQR.NombreCompleto}_QR.pdf`} y={50}>
                      {({ toPdf }) => <button onClick={toPdf}>descargar QR</button>}
                    </Pdf>
                  </Grid>
                )}
              </Box>
            </>
          )}
        </Paper>
      )}
    </>
  );
};

const recaptcha = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
      <Pregistration />
    </GoogleReCaptchaProvider>
  );
};
export default recaptcha;
