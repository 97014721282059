// Material UI
import { Container, Grid, Box, Typography } from '@mui/material';

// Componentes
import UserForm from '@components/Compromisos/UserForm';
import AddressForm from '@components/Compromisos/AddressForm';
import ContactInformationForm from '@components/Compromisos/ContactInformationForm';
import PersonalInformationForm from '@components/Compromisos/PersonalInformationForm';

const FormRegister = (props) => {
  const { catalogsFiltered, dataEvent, errors, touched, values, setValues, handleChange, load } = props;

  return (
    <Box component="section" pt={6} my={6} sx={{ alignItems: 'center' }}>
      <Container>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h4" color={'#1769aa'} gutterBottom>
              Para poderse registrar en {`${dataEvent.Actividad}`} es necesario proporcionar la siguiente
              información.
            </Typography>
          </Grid>
          {![3, 4].includes(dataEvent.idModoEvento) && (
            <Grid item xs={12}>
              <UserForm
                catalogs={catalogsFiltered}
                loadigCatalogs={load}
                errors={errors}
                touched={touched}
                values={values}
                setValues={setValues}
                handleChange={handleChange}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <PersonalInformationForm
              catalogs={catalogsFiltered}
              loadigCatalogs={load}
              errors={errors}
              touched={touched}
              values={values}
              setValues={setValues}
              handleChange={handleChange}
              NotINE={true}
              NotSimple={true}
              isMenor={values.isMenor}
              isBasic={dataEvent.idModoEvento === 4}
            />
          </Grid>
          <Grid item xs={12}>
            <ContactInformationForm
              errors={errors}
              touched={touched}
              values={values}
              handleChange={handleChange}
              emailShow={dataEvent.idModoEvento !== 4}
            />
          </Grid>
          <Grid item xs={12}>
            <AddressForm
              catalogs={catalogsFiltered}
              loadigCatalogs={load}
              errors={errors}
              touched={touched}
              values={values}
              setValues={setValues}
              handleChange={handleChange}
              movile={true}
              isBasic={dataEvent.idModoEvento === 4}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default FormRegister;
