import React, { useEffect, useState } from 'react';
import AdminLayout from '@components/MainPage/AdminLayout';
import { Container, Typography, Grid, List, ListItem, ListItemText, CircularProgress } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';

import { getVars } from '@utils/global';
import middleware from '@middlewares/middleware';
import ActionHeader from '@components/Containers/ActionHeader';
import OficialiaServices from '@services/OficialiaServices';
import Swal from 'sweetalert2';
import ButtonCancelarRecibir from '@components/Oficialia/ButtonCancelarRecibir';

import './OficialiaPartes.css';

const OficiliaTransito = () => {
  const { user } = getVars('Token');
  const { id } = useParams();
  const idUser = user.id;
  const navigate = useNavigate();
  const [oficios, setOficios] = useState([]);
  const [dataUserPermisos, setDataUserPermisos] = useState(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    getOficiosEnTransito();
    getPermisosUsuarios();
  }, []);

  const getPermisosUsuarios = async () => {
    try {
      setLoading(true);

      const userPermitidos = {};

      if (user.UUID === id) {
        setDataUserPermisos({
          idUsuarioPropietario: user.id,
          idUsuarioPermitido: user.id,
          UUIDPropietario: user.UUID,
          UsuarioPropietario: user.Nombre,
          UUIDPermitido: user.UUID,
          UsuarioPermitido: user.Nombre,
        });
      } else {
        const res = await OficialiaServices.getPermisosPersonasOficialia({
          page: 0,
          pageSize: 25,
          filtered: [
            { id: 'p.idUsuarioPermitido', value: user.id, filter: '=' },
            { id: 'up.UUID', value: id, filter: '=' },
          ],
        });
        const { results, response, message } = res;

        if (results) {
          if (response.data.length > 0) setDataUserPermisos(response.data[0]);
        } else {
          setDataUserPermisos(null);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Swal.fire({ title: error.message, icon: 'warning' });
    }
  };

  const handleRecibirClick = (item) => {
    Swal.fire({
      title: '¿Confirmar recepción del documento?',
      text: '¿Desea recibir este documento?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, recibir',
      cancelButtonText: 'Cancelar',
      customClass: {
        popup: 'swal2-custom', // Aplica la clase personalizada
      },
    }).then((result) => {
      if (result.isConfirmed) {
        btnConfirmar(item);
      }
    });

    document.querySelector('.swal2-popup').style.zIndex = 1000;
  };

  const handleCancelarClick = (item) => {
    Swal.fire({
      title: '¿Confirmar cancelar envío del documento?',
      text: `¿Desea cancelar el envío del documento: ${item.Asunto}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, cancelar',
      cancelButtonText: 'Cancelar',
      customClass: {
        popup: 'swal2-custom', // Aplica la clase personalizada
      },
    }).then((result) => {
      if (result.isConfirmed) {
        btnCancelarTransferencia(item);
      }
    });

    document.querySelector('.swal2-popup').style.zIndex = 1000;
  };

  const btnConfirmar = async (item) => {
    const res = await OficialiaServices.aceptarOficiosEnTransito({
      idUserReq: dataUserPermisos.idUsuarioPropietario,
      idMovimiento: item.id,
      idOficio: item.idOficio,
    });
    const { results, response, message } = res;

    if (results) {
      setOficios(oficios.filter((item) => item.id !== response.data[0].id));
      Swal.fire({ title: message, icon: 'success' });
    } else {
      Swal.fire({ title: message, icon: 'warning' });
    }
  };

  const btnCancelarTransferencia = async (item) => {
    const res = await OficialiaServices.cancelOficiosEnTransito({
      idMovimiento: item.id,
      idOficio: item.idOficio,
    });
    const { results, response, message } = res;

    if (results) {
      setOficios(oficios.filter((item) => item.id !== response.data[0].id));
      Swal.fire({ title: message, icon: 'success' });
    } else {
      Swal.fire({ title: message, icon: 'warning' });
    }
  };

  const getOficiosEnTransito = async () => {
    try {
      setLoading(true);

      const res = await OficialiaServices.getOficiosEnTransito({
        page: 0,
        pageSize: 99999,
        filtered:
          middleware.checkMenuActionId('admin') === true
            ? []
            : [
                {
                  id: ` and r.idUsuarioRecibe is null and ( r.idParaQuien in (select id from usuarios where UUID='${id}' and (id = ${user.id} or id in (select idUsuarioPropietario from cde_oficialia_permisos where idUsuarioPermitido=${user.id})))  or r.idDeQuien in (select id from usuarios where UUID='${id}' and (id = ${user.id} or id in (select idUsuarioPropietario from cde_oficialia_permisos where idUsuarioPermitido=${user.id}))) )`,
                  filterQuery: true,
                },
              ],
      });
      const { results, response, message } = res;

      if (results) {
        setOficios(response.data);
      } else {
        setOficios([]);
        Swal.fire({ title: message, icon: 'warning' });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Swal.fire({ title: error.message, icon: 'warning' });
    }
  };

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <ActionHeader
          title="Recepción de oficios"
          isAction={true}
          titleButton="Regresar"
          iconButton="arrow_back"
          handleclick={() => navigate(-1)}
        />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div
              style={{
                padding: 20,
                backgroundColor: 'white',
                maxWidth: 600,
                margin: 'auto',
                marginTop: '10%',
              }}
            >
              <h2>Oficios en tránsito</h2>
              {isLoading ? (
                <CircularProgress />
              ) : oficios.length ? (
                <List>
                  {oficios.map((item) =>
                    dataUserPermisos ? (
                      <ListItem key={item.idOficio}>
                        <ListItemText
                          primary={parse(
                            `<strong>De:</strong> ${item.DeQuien} <strong>Para:</strong> ${
                              item.ParaQuien
                            }<br/> <strong>Oficio:</strong> ${
                              item.NumOficio ? `Num: ${item.NumOficio} ${item.Asunto}` : item.Asunto
                            }`
                          )}
                          secondary={parse(
                            `<strong>Fecha Recibido:</strong> ${item.FechaRecibido} |  <strong>Firma:</strong> ${item.NombreCompleto}  <strong>Cargo:</strong> ${item.Cargo}`
                          )}
                        />
                        {/* Simplificamos para ver si la comparación es correcta */}

                        <ButtonCancelarRecibir
                          Origen={item.idParaQuien.toString()}
                          Destino={idUser.toString()}
                          btnIgual={handleRecibirClick}
                          btnDiferente={handleCancelarClick}
                          label={
                            item.idParaQuien === dataUserPermisos.idUsuarioPropietario
                              ? 'Recibir'
                              : 'Cancelar'
                          }
                          item={item}
                        />
                      </ListItem>
                    ) : (
                      <Typography variant="caption" color="error">
                        Sin permisos para recibir archivos
                      </Typography>
                    )
                  )}
                </List>
              ) : (
                <Typography variant="caption" color="error">
                  No hay oficios en tránsito
                </Typography>
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
    </AdminLayout>
  );
};
export default OficiliaTransito;
