import { ApiExec, ApiExecPublic, ApiExecDecrypted, downloadFile } from '@utils/ApiExec';

class EventService {
  getMyRecords = (data) => ApiExec(data, 'eventos/get-my-records', 'POST');

  getPublicEvent = (data) => ApiExecPublic(data, 'app/eventos/public-get-event', 'POST');

  getAllEvents = (data) => ApiExecPublic(data, 'app/eventos/public-get-events', 'POST');

  publicGetUser = (data) => ApiExecPublic(data, 'app/eventos/public-get-user', 'POST');

  publicGetUserEvent = (data) => ApiExecPublic(data, 'app/eventos/public-get-user-event', 'POST');

  publicSetGuestEvent = (data) => ApiExecPublic(data, 'app/eventos/public-set-guest-event', 'POST');

  publicRegisterGuest = (data) => ApiExecPublic(data, 'app/eventos/public-register-guest', 'POST');

  publicRegisterGuestSencillo = (data) =>
    ApiExecPublic(data, 'app/eventos/public-register-guest-sencillo', 'POST');

  getEventsUsers = (data) => ApiExec(data, 'eventos/get-event-users', 'POST');

  getEventsUsersInvited = (data) => ApiExec(data, 'eventos/get-event-users-invited', 'POST');

  setAttendanceUsers = (data) => ApiExec(data, 'eventos/set-attendance-users', 'POST');

  setAttendance = (data) => ApiExec(data, 'eventos/set-attendance', 'POST');

  setEvent = (data) => ApiExecDecrypted(data, 'eventos/set-event', 'POST', false);

  updateEvent = (data) => ApiExecDecrypted(data, 'eventos/update-event', 'POST', false);

  deleteEvent = (data) => ApiExec(data, 'eventos/delete-event', 'POST');

  getQuestions = (data) => ApiExecPublic(data, 'app/eventos/public-get-questions', 'POST');

  deleteQuestions = (data) => ApiExec(data, 'eventos/delete-questions', 'POST');

  setQuestions = (data) => ApiExec(data, 'eventos/set-questions', 'POST');

  getEventosCalendar = (data) => ApiExec(data, 'eventos/get-events-calendar', 'POST');

  getEventosList = (data) => ApiExec(data, 'eventos/get-events-list', 'POST');

  getEventosDashboard = (data) => ApiExec(data, 'eventos/get-event-dashboard', 'POST');

  getEventosDetails = (data) => ApiExec(data, 'eventos/get-events-details', 'POST');

  exportEventosDetails = (data) =>
    downloadFile({ api: 'eventos/export-events-details', method: 'POST', data });

  setOrganizador = (data) => ApiExec(data, 'eventos/set-event-organizador', 'POST');

  setInvitado = (data) => ApiExec(data, 'eventos/set-event-invitado', 'POST');

  downloadCroquis = (data) => ApiExec(data, 'eventos/download-croquis', 'POST');
}

const events = new EventService();
export default events;
