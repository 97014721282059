import { useState, useEffect } from 'react';
import useScrollToTop from '@hooks/useScrollToTop';
import { useFormik } from 'formik';
import { Box, Button, Card, CardContent, Container, Icon, TextField, CircularProgress } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useNavigate } from 'react-router-dom';
import OficialiaServices from '@services/OficialiaServices';
import dayjs from 'dayjs';
import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import LoadingForm from '@components/LinearProgress/LoadingForm';
import Swal from 'sweetalert2';
import OficiolInformationForm from '@components/Oficialia/OficiolInformationForm';
import { isEmptyOrNullObject } from '@utils/validations';
import { getVars } from '@utils/global';
import { OficialiaAddInterface } from '@data/interfaces/OficialiaInterfaces';
import { OficialiaAddSchema } from '@data/schemas/OficialiaSchemas';
import { handleAttrs, convertBase64URLv2 } from '@utils/Utilities';

const CapturaOficio = () => {
  useScrollToTop();

  const navigate = useNavigate();
  const { user } = getVars('Token');

  //LoadigForm
  const [isLoadigForm, setIsLoadingForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSuccessFormSave, setIsSuccessFormSave] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);
  const [finished, setFinished] = useState(false);
  const [previousPage, setPreviousPage] = useState(-1);

  const handleVerification = (values) => {
    if (!isEmptyOrNullObject(values)) {
      handleSave(values);
    }
  };

  const handleSave = async (values) => {
    setLoading(true);

    let data = {};
    Object.keys(OficialiaAddInterface).forEach((key) => {
      data[key] = values[key];
    });

    let filesOficio = [];
    let filesAdjunto = [];

    if (values.OficioAdjuntos.length) {
      for (const oficio of values.OficioAdjuntos) {
        const item = await convertBase64URLv2({
          url: oficio.url,
          name: oficio.name,
          mimeType: oficio.mimeType,
          size: oficio.size,
        });
        filesOficio = [
          ...filesOficio,
          {
            classificationId: 1,
            file: item.base64,
            name: item.name,
            mimeType: item.mimeType,
            size: oficio.size,
          },
        ];
      }
    }

    if (values.FotosAdjuntos.length) {
      for (const adjunto of values.FotosAdjuntos) {
        const item = await convertBase64URLv2({
          url: adjunto.url,
          name: adjunto.name,
          mimeType: adjunto.mimeType,
          size: adjunto.size,
        });

        filesAdjunto = [
          ...filesAdjunto,
          {
            classificationId: 1,
            file: item.base64,
            mimeType: item.mimeType,
            name: item.mimeType,
            size: item.size,
          },
        ];
      }
    }

    const dataItem = {
      FolioInterno: data.FolioInterno || null,
      Asunto: data.Asunto,
      FolioOficio: data.FolioOficio,
      SolicitaRespuesta: data.SolicitaRespuesta,
      TieneAdjuntos: data.TieneAdjuntos,
      ComentarioAdjuntos: data.TieneAdjuntos === 1 ? data.ComentarioAdjuntos : '',
      FotosAdjuntos: filesAdjunto,
      OficioAdjuntos: filesOficio,
      idPersonaEnvia: data.idPersonaEnvia,
      idParaQuien: data.idParaQuien,
      idPrioiridad: data.idPrioiridad,
      idTipoDocumento: data.idTipoDocumento,
      idUsuarioRecibe: data.idUsuarioRecibe,
      FechaOficio: dayjs(data.FechaOficio).format('YYYY-MM-DD'),
      FechaTermino: data.FechaTermino ? dayjs(data.FechaTermino).format('YYYY-MM-DD HH:mm:ss') : null,
      FechaRecibido: data.FechaRecibido
        ? dayjs(data.FechaRecibido).format('YYYY-MM-DD HH:mm:ss')
        : dayjs().format('YYYY-MM-DD HH:mm:ss'),
    };

    console.log('=> setOficialia:', JSON.stringify(dataItem));

    const res = await OficialiaServices.setOficialia(dataItem);
    const { results, response, message } = res;

    if (!results) {
      setLoading(false);
      Swal.fire({ title: message, icon: 'warning' });
      return true;
    }

    Swal.fire({ title: message, icon: 'success' });
    navigate(previousPage);
  };

  const formik = useFormik({
    initialValues: OficialiaAddInterface,
    validationSchema: OficialiaAddSchema,
    onSubmit: (values) => {
      handleVerification(handleAttrs(values));
    },
  });

  useEffect(() => {
    if (user.id) {
      formik.handleChange({
        target: {
          name: 'idUsuarioRecibe',
          value: user.id,
        },
      });
    }
  }, [user.id]);

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <ActionHeader
          title="Recepción de oficios"
          isAction={true}
          titleButton="Regresar"
          iconButton="arrow_back"
          handleclick={() => navigate(previousPage)}
        />
        <Card className="card-primary">
          <CardContent>
            <LoadingForm
              loadinMessage={'Cargando datos...'}
              successMessage="¡Cargado con exito!"
              isLoading={isLoadigForm}
              success={isSuccessFormSave}
              isOpen={openLinearProgress}
              setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
            />

            <Grid2 container spacing={3} sx={{ mt: 1 }}>
              <Grid2 xs={12} sm={12} md={4} lg={4}>
                <TextField
                  required={true}
                  disabled={true}
                  label="Nombre de quien recibe"
                  value={`${user.Nombre} ${user.Paterno}`}
                  name="Nombre"
                  sx={{ width: '100%' }}
                  type="text"
                  variant="outlined"
                  size="small"
                  className="fixed-input"
                />
              </Grid2>
            </Grid2>

            <OficiolInformationForm
              errors={formik.errors}
              touched={formik.touched}
              values={formik.values}
              setValues={formik.setValues}
              handleChange={formik.handleChange}
              finished={finished}
            />

            <Grid2 xs={12} sm={12} md={12} lg={12}>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                {!loading ? (
                  <Button
                    type="button"
                    color="primaryDark"
                    variant="contained"
                    size="small"
                    onClick={formik.submitForm}
                    startIcon={<Icon>save</Icon>}
                  >
                    Guardar
                  </Button>
                ) : (
                  <CircularProgress />
                )}
              </Box>
            </Grid2>
          </CardContent>
        </Card>
      </Container>
    </AdminLayout>
  );
};

export default CapturaOficio;
