import React, { useEffect, useState } from 'react';
import {
  Container,
  Box,
  IconButton,
  Tooltip,
  Typography,
  TextField,
  Grid,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Paper,
  Icon,
  Modal,
  useMediaQuery,
  useTheme,
  TableContainer,
  LinearProgress,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import FileUpload from '@components/Oficialia/FileUpload';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ActionHeader from '@components/Containers/ActionHeader';
import AutocompleteGnerico from '@components/Oficialia/Autocomplete';
import EstatusSeguimientoAutocomplete from '@components/Oficialia/EstatusSeguimientoAutocomplete';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import OficialiaServices from '@services/OficialiaServices';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useParams, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import middleware from '@middlewares/middleware';
import AdminLayout from '@components/MainPage/AdminLayout';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DrawIcon from '@mui/icons-material/Draw';
import ForwardIcon from '@mui/icons-material/Forward';
import { getVars } from '@utils/global';
import DirectorioCDEParaQuien from '@components/Oficialia/DirectorioCDEParaQuien';
import CustomTableOficios from '@components/Tables/CustomTableOficios';
import dayjs from 'dayjs';
import { convertBase64URLv2 } from '@utils/Utilities';
import { downloadFile } from '@utils/ApiExec';

import icopdf from '../../assets/img/iconos/pdf.png';
import icodocx from '../../assets/img/iconos/docx.png';
import icoxlsx from '../../assets/img/iconos/xlsx.png';
import icoimg from '../../assets/img/iconos/img.png';
import icorar from '../../assets/img/iconos/rar.png';
import icopptx from '../../assets/img/iconos/pptx.png';
import icounknow from '../../assets/img/iconos/unknow.png';
import icozip from '../../assets/img/iconos/zip.png';

const OficioView = ({ oficio, handleOpen, id, handleChange, formData, setFormData, setData }) => {
  const { user } = getVars('Token');
  const [dataSeguimiento, setDataSeguimiento] = useState([]);
  const [dataFolios, setDataFolios] = useState([]);
  const [openSeguimiento, setOpenSeguimiento] = useState(false);
  const [openFolio, setOpenFolio] = useState(false);
  const [errors, setErrors] = useState({ Folio: null, Asunto: null });
  const [loadingSeguimiento, setLoadingSeguimiento] = useState(false);
  const [loadingFolio, setLoadingFolio] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [totalSeguimiento, setTotalSeguimiento] = useState(0);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    Folio: {
      Anio: null,
      Folio: null,
      Nomenclatura: '',
      id: null,
      idEmpresaArea: null,
    },
    AsuntoFolio: '',
    Estatus: { id: oficio.idEstatus, Estatus: oficio.Estatus },
    Seguimiento: '',
    ArchivosAdjuntos: [],
  });
  const [params, setParams] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
    sorted: [],
  });
  const [hasMore, setHasMore] = useState(true);
  const tiposMimePermitidos = {
    'application/pdf': icopdf,
    'application/msword': icodocx,
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': icodocx,
    'application/vnd.ms-excel': icoxlsx,
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': icoxlsx,
    'application/vnd.ms-powerpoint': icopptx,
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': icopptx,
    'application/x-rar-compressed': icorar,
    'application/zip': icozip,
    'image/jpeg': icoimg,
    'image/png': icoimg,
    'image/gif': icoimg,
  };

  useEffect(() => {
    getDataSeguimiento();
    // getDataArchivosSeguimiento();
    getFolioPermisos();
  }, []);

  useEffect(() => {}, [dataSeguimiento]);

  const getPublicFile = async (archivo) => {
    try {
      await downloadFile({
        api: 'oficialia/get-PublicFile',
        method: 'POST',
        data: {
          idArchivo: archivo.id,
          idOficio: archivo.idOficio,
          path: archivo.PathAWS,
          mime: archivo.MimeType,
          nombre: archivo.Nombre,
        },
      });
    } catch (err) {
      console.log('=>err: ', err);
    }

    // const res = await OficialiaServices.getPublicFile({
    //   path,
    // });
  };

  const obtenerIconoPorExtension = (extension) => {
    switch (extension) {
      case 'pdf':
        return icopdf;
      case 'doc':
      case 'docx':
        return icodocx;
      case 'xls':
      case 'xlsx':
        return icoxlsx;
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return icoimg;
      case 'rar':
        return icorar;
      case 'ppt':
      case 'pptx':
        return icopptx;
      case 'zip':
        return icozip;
      default:
        return icounknow;
    }
  };

  const obtenerIconoArchivo = (archivo) => {
    const { MimeType, Nombre } = archivo;

    // Verificar primero si el tipo MIME coincide con los permitidos
    if (tiposMimePermitidos[MimeType]) {
      return tiposMimePermitidos[MimeType];
    }

    // Si el MIME-Type no es confiable, usar la extensión como respaldo
    const extension = Nombre.split('.').pop().toLowerCase();
    return obtenerIconoPorExtension(extension);
  };

  const formatoTamaño = (size) => {
    return size >= 1048576 ? `${(size / 1048576).toFixed(2)} MB` : `${(size / 1024).toFixed(2)} KB`;
  };

  const getDataSeguimiento = async () => {
    try {
      setLoadingSeguimiento(true);
      const res = await OficialiaServices.getOficiosSeguimiento({
        page: 0,
        pageSize: 50,
        filtered: [{ id: 'o.UUID', value: id, filter: '=' }],
        sorted: [{ id: 's.FechaCreo', value: 'DESC' }],
      });
      const { results, response, message } = res;

      if (results) {
        if (response.data.length) {
          let data = response.data.map((item) => {
            return {
              ...item,
              MisAdjuntos: item.adjuntos.map((archivo, index) => {
                return (
                  <Grid item key={index}>
                    <Tooltip title={archivo.Nombre}>
                      <div
                        style={{
                          position: 'relative',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '50px',
                          height: '100px',
                          borderRadius: '8px',
                          backgroundColor: '#f0f0f0',
                          cursor: 'pointer',
                          padding: '8px',
                        }}
                      >
                        <img
                          src={obtenerIconoArchivo(archivo)}
                          alt={archivo.Nombre}
                          style={{ width: '30px', height: '40px' }}
                        />
                        <Typography variant="body2" align="center" sx={{ marginTop: '4px' }}>
                          {formatoTamaño(archivo.Peso)}
                        </Typography>
                        <IconButton
                          size="small"
                          style={{
                            position: 'absolute',
                            top: '4px',
                            right: '4px',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            color: 'white',
                          }}
                          onClick={() => getPublicFile(archivo)}
                        >
                          <CloudDownloadIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  </Grid>
                );
              }),
            };
          });
          setDataSeguimiento(data);
        } else {
          setDataSeguimiento([]);
        }
        setTotalSeguimiento(response.total);

        const end = (params.page + 1) * params.pageSize;
        if (end >= response.total) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
      } else {
        setDataSeguimiento([]);
        Swal.fire({ title: message, icon: 'warning' });
      }
      setLoadingSeguimiento(false);
    } catch (error) {
      setLoadingSeguimiento(false);
      Swal.fire({ title: error.message, icon: 'warning' });
    }
  };

  const handlePagination = ({ page, pageSize }) => {
    const params = {
      ...params,
      page: page,
      pageSize: pageSize,
    };
    setParams(params);
  };

  const handleSort = (e) => {
    var OrdandoPor = [];

    if (e.orderBy === 'FechaCreo') {
      OrdandoPor.push({ id: 's.FechaCreo', value: e.order });
    }

    if (e.orderBy === 'UsuarioSeguimiento') {
      OrdandoPor.push({
        id: "concat_ws(' ', u.Nombre, u.Paterno)",
        value: e.order,
      });
    }

    if (e.orderBy === 'Mensaje') {
      OrdandoPor.push({ id: 's.Mensaje', value: e.order });
    }

    const paramsNew = {
      ...params,
      sorted: OrdandoPor,
    };

    setParams(paramsNew);
  };

  const handleSeguimientoOpen = () => setOpenSeguimiento(true);
  const handleSeguimientoClose = () => setOpenSeguimiento(false);

  const handleFolioOpen = () => setOpenFolio(true);
  const handleFolioClose = () => setOpenFolio(false);

  const handleFilesChange = (files) => {
    setState({ ...state, ArchivosAdjuntos: files });
  };

  const getFolioPermisos = async () => {
    const res = await OficialiaServices.getFoliosPermisos({
      Anio: dayjs().year(),
      page: 0,
      pageSize: 100,
      filtered: [
        { id: 'Anio', value: dayjs().year(), filter: '=' },
        {
          id: ` and idEmpresaArea in (select idEmpresaArea from cde_oficialia_permisos where idUsuarioPermitido=${user.id} and Foliar=1)`,
          filterQuery: true,
        },
      ],
    });

    const { results, response } = res;
    if (results) {
      setDataFolios(response.data);
      if (response.data.length === 1) setState({ ...state, Folio: response.data[0] });
    }
  };

  const handleSeguimientoSubmit = async () => {
    //onSubmit(formData);
    setLoading(true);

    if (state.Seguimiento === '') {
      Swal.fire({
        title: 'Se requiere escriba un seguimiento',
        icon: 'warning',
      });
      setLoading(false);
      return true;
    }

    if (state.Seguimiento.length < 10) {
      Swal.fire({
        title: 'Se requiere escriba un seguimiento',
        icon: 'warning',
      });
      setLoading(false);
      return true;
    }

    let filesOficio = [];

    if (state.ArchivosAdjuntos.length) {
      for (const oficio of state.ArchivosAdjuntos) {
        const item = await convertBase64URLv2({
          url: oficio.url,
          name: oficio.name,
          mimeType: oficio.mimeType,
        });
        filesOficio = [
          ...filesOficio,
          {
            classificationId: 1,
            file: item.base64,
            name: item.name,
            mimeType: item.mimeType,
            size: item.size,
          },
        ];
      }
    }

    const res = await OficialiaServices.setSeguimientoOficio({
      idOficio: oficio.id,
      TieneAdjuntos: 0,
      idEstatus: state.Estatus.id,
      EstatusOficio: state.Estatus.Estatus,
      Seguimiento: state.Seguimiento,
      ArchivosAdjuntos: filesOficio,
    });
    const { results, response, message } = res;

    setLoading(false);

    if (results) {
      setState({
        ...state,
        Estatus: { id: null, Estatus: '', Clave: '' },
        Seguimiento: '',
      });
      getDataSeguimiento();
      handleSeguimientoClose();
      return true;
    }
  };

  const handleAsignarFolioSubmit = async () => {
    //onSubmit(formData);
    setErrors({ Folio: null, Asunto: null, General: null });
    setLoadingFolio(true);

    if (oficio.FolioInterno) {
      setLoadingFolio(false);

      setErrors({ ...errors, General: 'El oficio ya tiene asignado un folio' });
      // Swal.fire({
      // 	title: "Seleccione un folio",
      // 	icon: "warning",
      // });
      return true;
    }

    if (!state.Folio) {
      setLoadingFolio(false);

      setErrors({ ...errors, Folio: 'Seleccione un folio' });
      // Swal.fire({
      // 	title: "Seleccione un folio",
      // 	icon: "warning",
      // });
      return true;
    }

    if (oficio.Asunto === '') {
      setLoadingFolio(false);
      setErrors({
        ...errors,
        Asunto: 'Se requiere escriba un Asunto para el folio',
      });
      // Swal.fire({
      // 	title: "Se requiere escriba un Asunto para el folio",
      // 	icon: "warning",
      // });
      return true;
    }

    if (oficio.Asunto.length < 8) {
      setLoadingFolio(false);
      setErrors({
        ...errors,
        Asunto: 'Se requiere escriba un Asunto para el folio',
      });
      // Swal.fire({
      // 	title: "Se requiere escriba un asunto para el folio",
      // 	icon: "warning",
      // });
      return true;
    }

    const res = await OficialiaServices.AsignarFolioOficio({
      idOficio: oficio.id,
      Anio: state.Anio,
      idEmpresaArea: state.idEmpresaArea,
      Asunto: oficio.Asunto,
    });
    const { results, response, message } = res;

    setLoadingFolio(false);

    if (results) {
      setData({ ...oficio, FolioInterno: response.data.FolioInterno });

      handleFolioClose();
      return true;
    } else {
      setErrors({ ...errors, General: message });
    }
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setState((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <Paper sx={{ padding: 3, maxWidth: 800, margin: 'auto' }}>
      <Box sx={{ marginTop: 3, textAlign: 'center' }}>
        {/* <Button
					variant="outlined"
					startIcon={<EditIcon />}
					onClick={handleEdit}
					sx={{ marginRight: 1 }}
				>
					Editar
				</Button> */}
        {middleware.checkMenuActionId('foliar', 352) ? (
          <Button
            variant="outlined"
            startIcon={<DrawIcon />}
            onClick={handleFolioOpen}
            sx={{ marginRight: 1 }}
          >
            Asignar Folio
          </Button>
        ) : null}
        <Button variant="outlined" startIcon={<ForwardIcon />} onClick={handleOpen}>
          Reenviar
        </Button>
      </Box>
      <Grid
        container
        spacing={2}
        sx={{ marginY: 2 }} // Esto agrega márgenes verticales arriba y abajo.
      >
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Tipo Oficio"
            value={oficio.TipoDocumento}
            InputProps={{ readOnly: true }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField fullWidth label="Folio" value={oficio.NumOficio} InputProps={{ readOnly: true }} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Folio Interno"
            value={oficio.FolioInterno || ''}
            InputProps={{ readOnly: true }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Fecha de oficio"
            value={oficio.FechaOficio}
            InputProps={{ readOnly: true }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Fecha recepción"
            value={oficio.FechaRecibido}
            InputProps={{ readOnly: true }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Fecha término"
            value={oficio.FechaTermino}
            InputProps={{ readOnly: true }}
          />
        </Grid>
        <Grid item xs={12}>
          {oficio.adjuntoOficio.map((archivo, index) => {
            return (
              <Grid item key={index}>
                <Tooltip title={archivo.Nombre}>
                  <div
                    style={{
                      position: 'relative',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100px',
                      height: '100px',
                      borderRadius: '8px',
                      backgroundColor: '#f0f0f0',
                      cursor: 'pointer',
                      padding: '8px',
                    }}
                  >
                    <img
                      src={obtenerIconoArchivo(archivo)}
                      alt={archivo.Nombre}
                      style={{ width: '30px', height: '40px' }}
                    />
                    <Typography variant="body2" align="center" sx={{ marginTop: '4px' }}>
                      {formatoTamaño(archivo.Peso)}
                    </Typography>
                    <IconButton
                      size="small"
                      style={{
                        position: 'absolute',
                        top: '4px',
                        right: '4px',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        color: 'white',
                      }}
                      onClick={() => getPublicFile(archivo)}
                    >
                      <CloudDownloadIcon />
                    </IconButton>
                  </div>
                </Tooltip>
              </Grid>
            );
          })}
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Asunto"
            multiline
            rows={3}
            value={oficio.Asunto}
            InputProps={{ readOnly: true }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>Prioridad</InputLabel>
            <Select value={oficio.idPrioridad} readOnly>
              <MenuItem value={1}>Normal</MenuItem>
              <MenuItem value={2}>Medio</MenuItem>
              <MenuItem value={3}>Urgente</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={<Checkbox checked={oficio.SolicitaRespuesta === 1 ? true : false} readOnly />}
            label="¿Requiere respuesta?"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="De quién va dirigido"
            value={`${oficio.PersonaEnvia} - ${oficio.CargoPersonaEnvia}`}
            InputProps={{ readOnly: true }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="A quién va dirigido"
            value={`${oficio.PersonaRecibe} - ${oficio.CargoPersonaRecibe}`}
            InputProps={{ readOnly: true }}
          />
        </Grid>

        {oficio.TieneAdjuntos === 1 ? (
          <>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Comentario de adjuntos"
                value={oficio.ComentarioAdjuntos}
                InputProps={{ readOnly: true }}
                multiline
                rows={2}
              />
            </Grid>

            <Grid item xs={12}>
              <Button variant="contained" startIcon={<AttachFileIcon />} sx={{ marginRight: 2 }}>
                Ver Adjuntos
              </Button>
            </Grid>
          </>
        ) : (
          <Typography variant="h6" gutterBottom>
            Sin datos adjuntos
          </Typography>
        )}
      </Grid>
      <Box sx={{ marginTop: 3, textAlign: 'center', width: '100%' }}>
        <Grid container spacing={2} xs={12}>
          <InfiniteScroll
            scrollableTarget={'scrollableDiv'}
            next={() => setParams({ ...params, page: params.page + 1 })}
            hasMore={hasMore}
            scrollThreshold={0.7}
            dataLength={dataSeguimiento.length}
            loader={loadingSeguimiento ? <LinearProgress /> : <></>}
            style={{ width: '100%' }}
          >
            {isMobile ? (
              <TableContainer sx={{ maxHeight: 588 }} id="scrollableDiv">
                <Grid container spacing={2}>
                  {dataSeguimiento.length > 0 ? (
                    dataSeguimiento.map((row, index) => (
                      <Grid item xs={12} key={index}>
                        <Box p={2} border={1} borderRadius={2}>
                          <Typography variant="h6">{row.FechaCreo}</Typography>
                          <Typography variant="body2">Usuario: {row.UsuarioSeguimiento}</Typography>
                          <Typography variant="body2">Comentario: {row.Mensaje}</Typography>
                        </Box>
                      </Grid>
                    ))
                  ) : (
                    <Grid item xs={12} key={'error'}>
                      <Box p={2} border={1} borderRadius={2} textAlign="center" boxShadow={3}>
                        <Icon color="error" style={{ fontSize: 48 }}>
                          search_off
                        </Icon>
                        <Typography variant="h6" color="textSecondary">
                          Sin seguimiento
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </TableContainer>
            ) : (
              <CustomTableOficios
                title="Seguimiento"
                titleseguimiento="Seguimiento"
                btnSeguimiento={handleSeguimientoOpen}
                rows={dataSeguimiento}
                columns={[
                  {
                    id: 'FechaCreo',
                    label: 'Fecha',
                    type: 'html',
                    minWidth: 100,
                    maxWidth: 180,
                  },
                  {
                    id: 'UsuarioSeguimiento',
                    label: 'Usuario',
                    type: 'html',
                    icon: 'reply',
                    iconColor: '#000F9F',
                    iconSize: 20,
                    minWidth: 150,
                    maxWidth: 400,
                  },
                  {
                    id: 'Mensaje',
                    label: 'Mensaje',
                    type: 'html',
                    icon: 'reply',
                    iconColor: '#000F9F',
                    iconSize: 20,
                    minWidth: 400,
                    maxWidth: 600,
                  },
                  {
                    id: 'MisAdjuntos',
                    label: 'MisAdjuntos',
                    type: 'html',
                  },
                ]}
                total={totalSeguimiento}
                page={params.page}
                pageSize={params.pageSize}
                isLoading={loadingSeguimiento}
                handlePagination={handlePagination}
                handleSort={handleSort}
              />
            )}
          </InfiniteScroll>
        </Grid>
      </Box>
      <Modal open={openSeguimiento} onClose={handleSeguimientoClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom>
            Seguimiento al oficio
          </Typography>
          <EstatusSeguimientoAutocomplete value={state} setValue={setState} />
          <TextField
            label="Seguimiento"
            name="Seguimiento"
            value={state.Seguimiento}
            onChange={(e) => {
              setState((prevValues) => ({
                ...prevValues,
                Seguimiento: e.target.value, // Obteniendo el valor del campo
              }));
            }}
            fullWidth
            margin="normal"
            multiline
            rows={4}
          />
          <Grid2 xs={12} sm={12} md={5} lg={5}>
            <FileUpload title="Adjuntar archivos" onFilesChange={handleFilesChange} />
          </Grid2>
          {!loading ? (
            <Button variant="contained" color="primary" onClick={handleSeguimientoSubmit} sx={{ mt: 2 }}>
              Guardar
            </Button>
          ) : (
            <LinearProgress />
          )}
        </Box>
      </Modal>

      <Modal open={openFolio} onClose={handleFolioClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h8" component="h2" gutterBottom>
            Asignar Folio Interno
          </Typography>

          {!!errors.General ? (
            <Typography variant="h8" component="h2" gutterBottom sx={{ color: 'red' }}>
              {errors.General}
            </Typography>
          ) : null}
          <AutocompleteGnerico
            data={dataFolios}
            name="Folio"
            OptionLabel="Nomenclatura"
            label="Folios"
            value={state}
            onChange={setState}
            error={!!errors.Folio} // Indica si hay un error
            helperText={errors.Folio} // Muestra el mensaje de error
          />

          <TextField
            label="Asunto del folio"
            name="AsuntoFolio"
            value={oficio.Asunto}
            onChange={handleOnChange}
            fullWidth
            margin="normal"
            error={!!errors.Asunto}
            helperText={errors.Asunto}
          />

          <Button variant="contained" color="primary" onClick={handleAsignarFolioSubmit} sx={{ mt: 2 }}>
            Asignar folio
          </Button>
        </Box>
      </Modal>
    </Paper>
  );
};

const VerOficio = () => {
  const { id } = useParams();
  const { user } = getVars('Token');
  const navigate = useNavigate();
  const [previousPage, setPreviousPage] = useState(-1);
  const [data, setData] = useState(null);

  const [error, setError] = useState(null);
  const [dataHistory, setDataHistory] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);

  const [formData, setFormData] = useState({
    paraQuien: null,
    comentario: '',
    Estatus: { id: null, Estatus: '', Clave: '' },
    Seguimiento: '',
    AsuntoFolio: '',
  });

  useEffect(() => {
    if (id) {
      getData();
      getDataHistory();
    }
  }, [id]);

  const getData = async () => {
    try {
      console.log('<<<<< getOficialia', {
        page: 0,
        pageSize: 10,
        filtered: [
          { id: 'o.UUID', value: id, filter: '=' },
          {
            id: ` and o.id in (select idOficio from cde_oficialia_recepcion where (idParaQuien=${user.id} or idParaQuien in (Select idUsuarioPropietario from cde_oficialia_permisos where idUsuarioPermitido = ${user.id} and FechaElimino is null )))`,
            filterQuery: true,
          },
        ],
        sorted: [{ id: 'o.FechaRecibido', value: 'DESC' }],
      });
      setLoading(true);
      const res = await OficialiaServices.getOficialia({
        page: 0,
        pageSize: 10,
        filtered: [
          { id: 'o.UUID', value: id, filter: '=' },
          {
            id: ` and o.id in (select idOficio from cde_oficialia_recepcion where (idParaQuien=${user.id} or idParaQuien in (Select idUsuarioPropietario from cde_oficialia_permisos where idUsuarioPermitido = ${user.id} and FechaElimino is null )))`,
            filterQuery: true,
          },
        ],
        sorted: [{ id: 'o.FechaRecibido', value: 'DESC' }],
      });
      const { results, response, message } = res;

      if (results) {
        if (response.data.length) {
          setData(response.data[0]);

          //   setFormData({
          //     ...formData,
          //     Estatus: {
          //       id: response.data[0].idEstatus,
          //       Estatus: response.data[0].Estatus,
          //     },
          //   });
        } else {
          setData(null);
          setError('Sin resultados. No existe el documento que intenta leer.');
          Swal.fire({
            title: 'Sin resultados. No existe el documento que intenta leer.',
            icon: 'error',
          });
        }
      } else {
        setData(null);
        setError(message);
        Swal.fire({ title: message, icon: 'warning' });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Swal.fire({ title: error.message, icon: 'warning' });
    }
  };

  const getDataHistory = async () => {
    try {
      setLoading(true);
      const res = await OficialiaServices.getOficioRecibeHistory({
        page: 0,
        pageSize: 10,
        filtered: [{ id: 'o.UUID', value: id, filter: '=' }],
        sorted: [{ id: 'r.FechaCreo', value: 'DESC' }],
      });
      const { results, response, message } = res;

      if (results) {
        if (response.data.length) {
          setDataHistory(response.data);
        } else {
          setDataHistory(null);
          Swal.fire({
            title: 'No se pudo descargar el historial del oficio.',
            icon: 'error',
          });
        }
      } else {
        setData(null);
        Swal.fire({ title: message, icon: 'warning' });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Swal.fire({ title: error.message, icon: 'warning' });
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    //onSubmit(formData);

    if (formData.paraQuien === null) {
      Swal.fire({
        title: 'Seleccione persona a la que se lo asignara',
        icon: 'warning',
      });
      return true;
    }

    // if (data.idParaQuien === formData.paraQuien.id) {
    //   Swal.fire({
    //     title: 'Error: La persona seleccionada es la misma del oficio',
    //     icon: 'warning',
    //   });
    //   return true;
    // }

    if (formData.paraQuien.idUsuarioSIAN === null) {
      Swal.fire({
        title: 'Error: La persona no tiene cuenta SIAN',
        icon: 'warning',
      });
      return true;
    }

    const res = await OficialiaServices.setOficioReenviar({
      idOficio: data.id,
      idParaQuien: formData.paraQuien.idUsuarioSIAN,
      idDeQuien: user.id,
      Comentarios: formData.comentario,
    });
    const { results, response, message } = res;

    if (results) {
      getDataHistory();
      handleClose();
    }
  };

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <ActionHeader
          title="Ver oficio"
          isAction={true}
          titleButton="Regresar"
          iconButton="arrow_back"
          handleclick={() => navigate(previousPage)}
        />
        {data ? (
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <OficioView
                oficio={data}
                handleOpen={handleOpen}
                id={id}
                handleChange={handleChange}
                formData={formData}
                setFormData={setFormData}
                setData={setData}
              />

              <Modal open={open} onClose={handleClose}>
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                  }}
                >
                  <Typography variant="h6" component="h2" gutterBottom>
                    Reenviar Oficio
                  </Typography>
                  <DirectorioCDEParaQuien value={formData} setValue={setFormData} />
                  <TextField
                    label="Comentario"
                    name="comentario"
                    value={formData.comentario}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    multiline
                    rows={4}
                  />
                  <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ mt: 2 }}>
                    Guardar
                  </Button>
                </Box>
              </Modal>
            </Grid>
            <Grid item xs={4}>
              <Paper sx={{ padding: 1, maxWidth: 800, margin: 'auto' }}>
                <Typography variant="h6" gutterBottom>
                  Historial
                </Typography>
                <Grid item xs={12}>
                  <Timeline>
                    {dataHistory.map((item) => (
                      <TimelineItem key={item.FechaRecibe || item.ParaQuien}>
                        <TimelineOppositeContent color="text.secondary">
                          {item.FechaRecibe ? (
                            item.FechaRecibe
                          ) : (
                            <>
                              <AccessTimeIcon size={14} />
                              Pendiente
                              <br />
                              {item.FechaCreoX}
                            </>
                          )}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot />
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent
                          sx={{
                            width: '150px', // Ajusta el ancho según tu diseño
                            whiteSpace: 'normal', // Permite que el texto se ajuste en varias líneas
                            wordBreak: 'break-word', // Fuerza el ajuste de palabras largas
                            textAlign: 'left', // Alinea el texto a la izquierda
                          }}
                        >
                          {item.UsuarioRecibe.length > 0 ? item.UsuarioRecibe : item.ParaQuien}
                        </TimelineContent>
                      </TimelineItem>
                    ))}
                  </Timeline>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Paper sx={{ padding: 3, maxWidth: 800, margin: 'auto' }}>
              <Typography variant="h6" gutterBottom>
                {error ? error : 'Cargando....'}
              </Typography>
            </Paper>
          </Grid>
        )}
      </Container>
    </AdminLayout>
  );
};

export default VerOficio;
