import { useState, useEffect, useCallback, cloneElement } from 'react';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import { useFormik } from 'formik';
import { Swal } from '@utils/alerts';
import moment from 'moment';
import { useCatalogsPublic } from '@hooks/useCatalogsPublic';

// Material UI
import {
  Container,
  Grid,
  Box,
  Typography,
  Card,
  AppBar,
  Toolbar,
  Button,
  Stack,
  Skeleton,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import CssBaseline from '@mui/material/CssBaseline';
import { AppRegistration, Home } from '@mui/icons-material';
import RouteIcon from '@mui/icons-material/Route';
// Componentes
import SearchPeople from '@components/Events/SearchPeople';
import PreguntasRespuestasEventos from '@components/Events/PreguntasRespuestasEventos';
import QRPreregistro from '@components/Events/QRPreregistro';
import FormRegister from '@components/Events/FormRegister';
import CustomModal from '@components/Modal/CustomModal'; // Importa el modal personalizado

// Utils
import { isNullOrUndefined, isEmptyOrNullObject } from '@utils/validations';
import { validaINE, handleAttrs, validaCURP } from '@utils/Utilities';
import { RECAPTCHA_SITE_KEY } from '@utils/global';

// Servicios
import EventsServices from '@services/EventsServices';

// Data
import { CompromisoEventSchema, CompromisoBasicEventExternoSchema } from '@schemas/CompromisoSchemas';
import {
  CompromisoEventInterface,
  CompromisoBasicEventExternoInterface,
} from '@interfaces/EventosInterfaces';
import eventosImg from '@assets/img/eventosImg.jpg';

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return cloneElement(children, { elevation: trigger ? 4 : 0 });
}

const PreRegistro = (props) => {
  const catalogsParams = [
    { id: 'generos' },
    { id: 'entidades' },
    { id: 'municipios' },
    { id: 'tipo_vialidad' },
    { id: 'medios_contacto' },
  ];

  const { id } = useParams();

  const [isMobile, setIsMobile] = useState(false);
  const [dominantColor, setDominantColor] = useState('#00000');

  const [initialValues, setInitialValues] = useState(CompromisoEventInterface(''));
  const [validationSchema, setValidationSchema] = useState(CompromisoEventSchema);

  const [loading, setLoading] = useState(true);
  const [dataEvent, setDataEvent] = useState({});
  const [dataPreguntas, setDataPreguntas] = useState([]);
  const [flagQuestion, setFlagQuestion] = useState(false);

  const [datauser, setDataUser] = useState({});
  const [flagNot, setFlagNot] = useState(false);
  const [loadingRegister, setLoadingRegister] = useState(false);

  const [dataQR, setDataQR] = useState({});
  const [flagQR, setFlagQR] = useState(false);
  const [mensaje, setMensaje] = useState('');
  const [LoadingForm, setLoadingForm] = useState(false);

  const { catalogs, load } = useCatalogsPublic({
    catalogsOptions: catalogsParams,
    putDefaultOption: false,
  });
  const { executeRecaptcha } = useGoogleReCaptcha();

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (parseInt(dataEvent.idModoEvento) === 3) {
        values = { ...values, Password: '12345678', Confirm: '12345678', PIN: '12345' };
      }

      let newValues = { ...values };

      if (parseInt(dataEvent.idModoEvento) === 4) {
        delete newValues.idUsuarioPropietario;
        delete newValues.TelMensajes;
        delete newValues.TelMensajes;
        newValues.Username = newValues.Celular;

        handleSave(newValues);
      } else onChangeCaptcha(handleAttrs(newValues));
    },
  });

  useEffect(() => {
    getEventos(); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Cambiar esquema e interfaz según idModoEvento
    if (dataEvent.idModoEvento === 4) {
      setInitialValues(CompromisoBasicEventExternoInterface(dataEvent.id));
      setValidationSchema(CompromisoBasicEventExternoSchema);
    } else {
      setInitialValues(CompromisoEventInterface(''));
      setValidationSchema(CompromisoEventSchema);
    } // eslint-disable-next-line
  }, [dataEvent.idModoEvento]);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const mobileRegex = /Mobi/i;
    const isMobileDevice = mobileRegex.test(userAgent);
    setIsMobile(isMobileDevice);

    const imageUrl = isMobileDevice ? dataEvent.imgMovilURL ?? eventosImg : dataEvent.imgURL ?? eventosImg;

    const image = new Image();
    image.crossOrigin = 'anonymous';
    image.src = imageUrl;

    image.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = image.width;
      canvas.height = image.height;
      const context = canvas.getContext('2d');
      context.drawImage(image, 0, 0);

      const imageData = context.getImageData(0, 0, canvas.width, canvas.height).data;
      let totalR = 0;
      let totalG = 0;
      let totalB = 0;
      const totalPixels = imageData.length / 4;

      for (let i = 0; i < imageData.length; i += 4) {
        totalR += imageData[i];
        totalG += imageData[i + 1];
        totalB += imageData[i + 2];
      }

      const avgR = Math.floor(totalR / totalPixels);
      const avgG = Math.floor(totalG / totalPixels);
      const avgB = Math.floor(totalB / totalPixels);

      const rgbToHex = (r, g, b) => {
        const componentToHex = (c) => {
          const hex = c.toString(16);
          return hex.length === 1 ? '0' + hex : hex;
        };
        return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
      };

      const hexColor = rgbToHex(avgR, avgG, avgB);
      setDominantColor(hexColor);
    };
    // eslint-disable-next-line
  }, [dataEvent, navigator.userAgent]);

  const getEventos = async () => {
    try {
      setLoading(true);

      const result = await EventsServices.getPublicEvent({ uuid: id });
      const { results, response, message } = await result;

      if (results) {
        const event = response.data;

        const localParams = {
          page: 0,
          pageSize: 10,
          filtered: [
            { id: 'idEvento', filter: '=', value: event.id },
            { id: 'idTipoPregunta', filter: '=', value: 1 },
          ],
        };

        const result2 = await EventsServices.getQuestions(localParams);
        const { results: results2, response: response2, message: message2 } = await result2;

        if (results2) {
          formik.setFieldValue('idUsuarioPropietario', event.idUsuarioCreo, false);
          formik.setFieldValue('idEvento', event.id, false);

          if (parseInt(event.idModoEvento) === 3) {
            formik.setFieldValue('Password', '12345678', false);
            formik.setFieldValue('Confirm', '12345678', false);
            formik.setFieldValue('PIN', '12345', false);
          }

          setDataEvent(event);
          setDataPreguntas(response2.data);
        } else throw new Error(message2);
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning' });
      setDataEvent({});
      setDataPreguntas([]);
    } finally {
      setLoading(false);
    }
  };

  const RegisterEvent = async (answers) => {
    if (!datauser.id) {
      Swal.fire({ title: 'Hace falta su clave de elector', icon: 'warning' });
      return true;
    }

    if (dataPreguntas.length !== 0 && answers.length === 0 && datauser.pedir_cuestionario) {
      Swal.fire({ title: 'Hace falta contestar alguna pregunta del formulario.', icon: 'warning' });
      return true;
    }

    if (loadingRegister) return false;

    setLoadingRegister(true);

    const localParams = {
      idUsuarioInvitado: datauser.id,
      idEvento: dataEvent.id,
      answers,
      correo: datauser.Correo,
      celular: datauser.Celular,
      nombre: datauser.Nombre,
      paterno: datauser.Paterno,
      materno: datauser.Materno,
    };

    try {
      const result = await EventsServices.publicSetGuestEvent(localParams);
      const { results, response, message } = await result;

      if (results) {
        setFlagQR(true);
        setMensaje(message);
        setDataQR(response.data);
        setFlagQuestion(false);
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning' });
    } finally {
      setLoadingRegister(false);
    }
  };

  const RegisterEventWithoutQuestions = async (answers) => {
    if (!datauser.id) {
      Swal.fire({ title: 'Hace falta su clave de elector', icon: 'warning' });
      return true;
    }

    if (loadingRegister) return false;

    setLoadingRegister(true);

    const localParams = {
      idUsuarioInvitado: datauser.id,
      idEvento: dataEvent.id,
      answers,
      correo: datauser.Correo,
      celular: datauser.Celular,
      nombre: datauser.Nombre,
      paterno: datauser.Paterno,
      materno: datauser.Materno,
    };

    try {
      const result = await EventsServices.publicSetGuestEvent(localParams);
      const { results, response, message } = await result;

      if (results) {
        setFlagQR(true);
        setMensaje(message);
        setDataQR(response.data);
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning' });
    } finally {
      setLoadingRegister(false);
      setFlagQuestion(false);
    }
  };

  const onChangeCaptcha = useCallback(
    (values) => {
      if (!executeRecaptcha) return;

      executeRecaptcha('eventos')
        .then((token) => {
          const data = { ...values, token: token };
          handleVerification(handleAttrs(data));
        })
        .catch((error) => Swal.fire({ title: error, icon: 'warning' }));
    },
    // eslint-disable-next-line
    [executeRecaptcha]
  );

  const handleVerification = (values) => {
    if (!isEmptyOrNullObject(values)) {
      let res = { results: false, message: 'Datos no validados' };
      if (dataEvent.idModoEvento !== 4) {
        if (values.isMenor) {
          res = validaCURP(values.CURP, values.Nombre, values.Paterno, values.Materno);
        } else {
          res = validaINE(
            values.INE,
            values.Nombre,
            values.Paterno,
            values.Materno,
            values.idSexo,
            values.FechaNacimiento,
            values.idEstadoNacimiento
          );
        }
      } else res.results = true;

      if (!res.results) {
        Swal.fire({
          title: `¡Atención!`,
          text: res.message,
          icon: 'info',
          reverseButtons: true,
          showCancelButton: false,
          confirmButtonText: 'Ok',
        });
      } else handleSave(values);
    }
  };

  const handleSave = async (values) => {
    if (LoadingForm) return false;

    const isSencillo = dataEvent.idModoEvento === 4;

    setLoadingForm(true);
    let data = {};

    if (!isSencillo) {
      Object.keys(CompromisoEventInterface()).forEach((key) => {
        data[key] = values[key] !== null ? values[key] : CompromisoEventInterface()[key];
      });

      data = {
        ...data,
        VigenciaINE: values.VigenciaINE ? parseInt(values.VigenciaINE) : null,
        INE: values.INE ? values.INE.toUpperCase() : '',
        CURP: values.CURP ? values.CURP.toUpperCase() : '',
        SeccionVota: data.isMenor === 1 ? null : data.SeccionVota,
        Nombre: values.Nombre.toUpperCase(),
        Paterno: values.Paterno.toUpperCase(),
        Materno: values.Materno.toUpperCase(),
        EsMenor: values.isMenor ? 1 : 0,
        FechaNacimiento: moment(values.FechaNacimiento).format('YYYY-MM-DD'),
        CalleVive: `${values.TipoVialidad} ${values.CalleVive}`,
      };

      delete data.TipoVialidad;
      delete data.isUsuario;
      data = {
        ...data,
        Username: values.Celular,
        medioConfirmacion: 'sms',
        idEstructuraSocial: 1,
      };
      delete data.isMenor;
      delete data.idMedioContacto;
      delete data.Segmentos;
      delete data.TieneAuto;
      delete data.TieneExperiencia;
      delete data.AceptaCuidarOtra;

      if (dataEvent.idModoEvento === 3 && dataPreguntas.length !== 0 && data.answers.length === 0) {
        Swal.fire({ title: 'Hace Falta, contestar una pregunta del formulario.', icon: 'warning' });
        return true;
      }
    } else {
      data = values;
      delete data.answers;
    }

    const api = !isSencillo ? EventsServices.publicRegisterGuest : EventsServices.publicRegisterGuestSencillo;

    try {
      const result = await api(data);
      const { success, results, response, message } = await result;

      if (success && results) {
        if (response?.data?.filtered) {
          const result2 = await EventsServices.publicGetUserEvent(response.data);
          const { results: results2, response: response2, message: message2 } = await result2;

          if (results2) {
            setFlagQuestion(false);
            setFlagQR(true);
            setMensaje(message);
            setDataQR(response2.data);
            clearForm();
          } else throw new Error(message2);
        } else {
          setFlagQuestion(false);
          setFlagQR(true);
          setMensaje(message);
          setDataQR(response.data);
          clearForm();
        }
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning' });
    } finally {
      setLoadingForm(false);
    }
  };

  const clear = () => {
    setFlagNot(false);
    setFlagQR(false);
    setDataUser({ message: '' });
    setDataQR({});
    setFlagQuestion(false);

    formik.resetForm({});
    formik.setFieldValue('idUsuarioPropietario', dataEvent.idUsuarioCreo, false);
    formik.setFieldValue('idMedioContacto', 2, false);
    formik.setFieldValue('idEvento', dataEvent.id, false);
  };

  const clearForm = () => {
    formik.resetForm({});
    formik.setFieldValue('idUsuarioPropietario', dataEvent.idUsuarioCreo, false);
    formik.setFieldValue('idEvento', dataEvent.id, false);
  };

  const handleAttendence = (values) => {
    formik.setFieldValue('answers', values);

    if (!flagNot && flagQuestion) RegisterEvent(values);

    if (flagNot && !flagQR) formik.submitForm();
  };

  const [isDownloading, setIsDownloading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleDownload = async ({ id }) => {
    try {
      setIsDownloading(true);

      const result = await EventsServices.downloadCroquis({ id });

      const { success, response } = result;

      if (!success || !response || !response.data || response.data.length === 0) {
        throw new Error('No se encontró la imagen');
      }

      // Obtén la URL de la imagen desde la respuesta
      const imageUrl = response.data[0].imageURL;

      if (imageUrl) {
        // Hacer la solicitud para obtener la imagen como blob
        const imageBlob = await fetch(imageUrl).then((res) => res.blob());

        // Crear un enlace de descarga con un blob
        const downloadLink = document.createElement('a');
        const url = window.URL.createObjectURL(imageBlob);
        downloadLink.href = url;
        downloadLink.download = 'croquis.jpg';
        downloadLink.click();

        // Limpiar el objeto URL creado para evitar pérdidas de memoria
        window.URL.revokeObjectURL(url);
      } else {
        throw new Error('Imagen no disponible');
      }
    } catch (error) {
      Swal.fire({ title: error.message, icon: 'error' });
    } finally {
      setIsDownloading(false);
    }
  };

  // Estado para almacenar la URL de la imagen
  return (
    <>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar position="fixed" sx={{ backgroundColor: dominantColor }}>
          <Toolbar>
            {!flagNot && !flagQR && (
              <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                {dataEvent ? dataEvent.Actividad : 'Eventos'}
              </Typography>
            )}
            {(flagNot || flagQR) && (
              <Button
                variant="outlined"
                onClick={clear}
                sx={{ border: 0, p: 0 }}
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                startIcon={<Home />}
              >
                Inicio
              </Button>
            )}
          </Toolbar>
        </AppBar>
      </ElevationScroll>

      {loading ? (
        <Box sx={{ p: 2 }}>
          <Skeleton
            animation="wave"
            sx={{ bgcolor: 'aliceblue' }}
            variant="rectangular"
            width="100%"
            height="calc(100vh - 32px)"
          />
        </Box>
      ) : (
        <>
          <Box
            sx={{
              minHeight: '60vh',
              width: '100%',
              backgroundImage:
                Object.entries(dataEvent).length !== 0 &&
                isNullOrUndefined(isMobile ? dataEvent : dataEvent.imgURL)
                  ? `url(${eventosImg})`
                  : isMobile
                  ? `url(${dataEvent.imgMovilURL})`
                  : `url(${dataEvent.imgURL})`,
              backgroundSize: '100%',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              display: 'grid',
              placeItems: 'center',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                minHeight: '75vh',
                width: '100%',
                backgroundColor:
                  Object.entries(dataEvent).length !== 0 &&
                  isNullOrUndefined(isMobile ? dataEvent.imgMovilURL : dataEvent.imgURL)
                    ? 'rgba(255, 255, 255, 0.5)'
                    : 'rgba(0, 0, 0, 0)', // Color oscuro con una opacidad de 0.5
                zIndex: 1,
              }}
            />

            {Object.entries(dataEvent).length !== 0 && isNullOrUndefined(dataEvent.imgURL) && (
              <Container sx={{ zIndex: 2 }}>
                <Grid
                  container
                  item
                  xs={12}
                  lg={8}
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  sx={{ mx: 'auto', textAlign: 'center' }}
                >
                  {parse(dataEvent.textoPreregistro)}
                </Grid>
              </Container>
            )}
          </Box>

          <Card
            sx={{
              position: 'relative',
              zIndex: 3,
              p: 2,
              mx: { xs: 2, lg: 3 },
              mt: -8,
              mb: 4,
            }}
          >
            {/* Aquí va el Box para el botón "Ver Croquis" */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
              {Object.entries(dataEvent).length !== 0 && !isNullOrUndefined(dataEvent.croquisURL) && (
                // <Button
                //   onClick={() => setOpenModal(true)}
                //   disabled={isDownloading}
                //   sx={{ border: '1px solid', padding: '8px 16px' }}
                // >
                //   Ver Croquis
                // </Button>

                <Button
                  onClick={() => setOpenModal(true)}
                  disabled={isDownloading}
                  variant="outlined"
                  sx={{ ml: 'auto' }} // Alinea el botón a la derecha
                  endIcon={<RouteIcon />} // Coloca el ícono al final del botón
                >
                  Ver Croquis
                </Button>
              )}
            </Box>

            {!flagNot && !flagQR && !flagQuestion && (
              <SearchPeople
                dataEvent={dataEvent}
                datauser={datauser}
                dataPreguntas={dataPreguntas}
                setFlagNot={setFlagNot}
                setFlagQuestion={setFlagQuestion}
                setDataUser={setDataUser}
                errors={formik.errors}
                touched={formik.touched}
                values={formik.values}
                setValues={formik.setValues}
                handleChange={formik.handleChange}
                catalogsFiltered={catalogs}
                register={(e) => {
                  if (dataEvent.puedeRegistrarse === 1) RegisterEvent(formik.values?.answers ?? []);
                  else RegisterEventWithoutQuestions(formik.values?.answers ?? []);
                }}
                loadingRegister={loadingRegister}
              />
            )}

            {flagNot && !flagQR && (
              <FormRegister
                catalogsFiltered={catalogs}
                dataEvent={dataEvent}
                errors={formik.errors}
                touched={formik.touched}
                values={formik.values}
                setValues={formik.setValues}
                handleChange={formik.handleChange}
                load={load}
              />
            )}
            {flagQuestion && (
              <PreguntasRespuestasEventos
                titulo={dataEvent?.Actividad}
                preguntas={dataPreguntas}
                handleChange={handleAttendence}
                buttonOptions={{ title: 'Registrarme', icon: 'app_registration' }}
                justify="end"
                loading={loadingRegister || LoadingForm}
              />
            )}
            {flagQR && <QRPreregistro dataQR={dataQR} mensaje={mensaje} dataEvent={dataEvent} />}

            {flagNot && !flagQR && !flagQuestion && (
              <Grid item xs={12} sm={12} md={12}>
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={4}>
                  <LoadingButton
                    size="small"
                    color="primary"
                    onClick={formik.submitForm}
                    loading={LoadingForm}
                    loadingPosition="end"
                    endIcon={<AppRegistration />}
                    variant="contained"
                  >
                    <span>Registrarme</span>
                  </LoadingButton>
                </Stack>
                <br />
              </Grid>
            )}
          </Card>
        </>
      )}

      <CustomModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        title="CROQUIS"
        image={{ src: dataEvent.croquisURL }}
        displayImage={true}
        actionButtons={[
          {
            title: 'Descargar',
            icon: 'download',
            action: () => handleDownload({ id: dataEvent?.id }),
          },
        ]}
      />
    </>
  );
};

const recaptcha = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
      <PreRegistro />
    </GoogleReCaptchaProvider>
  );
};

export default recaptcha;
