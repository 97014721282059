import React from 'react';

import { Icon, TextField, InputAdornment, Box, Chip } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { isEmptyOrInvalidString, isTypePhone } from '@utils/validations';
import { Email } from '@mui/icons-material';

/* const TikTokIcon = ({ color = '#000000' }) => {
  return (
    <svg fill={color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="100%" height="100%">
      <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
    </svg>
  );
}; */

const ContactInformationForm = (props) => {
  const { errors, touched, values, handleChange, telephoneShow = true, emailShow = true } = props;

  return (
    <Box sx={{ mt: 2, mb: 2 }}>
      <Grid2 xs={12} sm={12} md={12} lg={12}>
        <Chip icon={<Icon>contact_phone</Icon>} label="Contacto" />
      </Grid2>

      <Grid2 container spacing={3} sx={{ mt: 2 }}>
        {telephoneShow && (
          <Grid2 xs={12} sm={12} md={3} lg={3}>
            <TextField
              error={touched.Celular && !isEmptyOrInvalidString(errors.Celular)}
              helperText={touched.Celular && errors.Celular && errors.Celular}
              sx={{ width: '100%' }}
              required
              label="Telefono Celular"
              type="text"
              name="Celular"
              onChange={(e) =>
                handleChange({
                  target: {
                    name: 'Celular',
                    value: isTypePhone(e.target.value) ? e.target.value : values.Celular,
                  },
                })
              }
              onBlur={(e) =>
                handleChange({
                  target: {
                    name: 'Celular',
                    value: isTypePhone(e.target.value) ? e.target.value : values.Celular,
                  },
                })
              }
              InputProps={{
                startAdornment: <InputAdornment position="start">{'+52'}</InputAdornment>,
              }}
              value={values.Celular}
              variant="outlined"
              size="small"
              className="fixed-input"
            />
          </Grid2>
        )}
        <Grid2 xs={12} sm={12} md={3} lg={3}>
          <TextField
            error={touched.Telefono && !isEmptyOrInvalidString(errors.Telefono)}
            helperText={touched.Telefono && errors.Telefono && errors.Telefono}
            sx={{ width: '100%' }}
            label="Telefono Fijo (opcional)"
            type="text"
            name="Telefono"
            onChange={(e) =>
              handleChange({
                target: {
                  name: 'Telefono',
                  value: isTypePhone(e.target.value) ? e.target.value : values.Telefono,
                },
              })
            }
            onBlur={(e) =>
              handleChange({
                target: {
                  name: 'Telefono',
                  value: isTypePhone(e.target.value) ? e.target.value : values.Telefono,
                },
              })
            }
            value={values.Telefono}
            variant="outlined"
            size="small"
            className="fixed-input"
          />
        </Grid2>
        <Grid2 xs={12} sm={12} md={3} lg={3}>
          <TextField
            error={touched.TelMensajes && !isEmptyOrInvalidString(errors.TelMensajes)}
            helperText={touched.TelMensajes && errors.TelMensajes && errors.TelMensajes}
            sx={{ width: '100%' }}
            label="Telefono Mensajes (opcional)"
            type="text"
            name="TelMensajes"
            onChange={(e) =>
              handleChange({
                target: {
                  name: 'TelMensajes',
                  value: isTypePhone(e.target.value) ? e.target.value : values.TelMensajes,
                },
              })
            }
            onBlur={(e) =>
              handleChange({
                target: {
                  name: 'TelMensajes',
                  value: isTypePhone(e.target.value) ? e.target.value : values.TelMensajes,
                },
              })
            }
            value={values.TelMensajes}
            variant="outlined"
            size="small"
            className="fixed-input"
          />
        </Grid2>
        {emailShow && (
          <Grid2 xs={12} sm={12} md={3} lg={3}>
            <TextField
              error={touched.Correo && !isEmptyOrInvalidString(errors.Correo)}
              placeholder="Correo Electrónico"
              helperText={touched.Correo && errors.Correo && errors.Correo}
              type="text"
              name="Correo"
              onChange={handleChange}
              onBlur={(e) => {
                handleChange({
                  target: {
                    name: e.target.name,
                    value: e.target.value.trim(),
                  },
                });
              }}
              value={values.Correo}
              size="small"
              variant="outlined"
              className="fixed-input"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Email />
                  </InputAdornment>
                ),
              }}
            />
          </Grid2>
        )}
      </Grid2>

      {/*   <Grid2 container spacing={3} sx={{ mt: 2 }}>

        <Grid2 xs={12} sm={12} md={3} lg={3}>
          <TextField
            error={
              touched.Facebook &&
              !isEmptyOrInvalidString(errors.Facebook)
            }
            placeholder="Facebook"
            helperText={
              touched.Facebook &&
              errors.Facebook &&
              errors.Facebook
            }
            type="text"
            name="Facebook"
            onChange={handleChange}
            value={values.Facebook}
            size="small"
            variant="outlined"
            className="fixed-input"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon>facebook</Icon>
                </InputAdornment>
              ),
            }}
          />
        </Grid2>
        <Grid2 xs={12} sm={12} md={3} lg={3}>
          <TextField
            error={
              touched.Instagram &&
              !isEmptyOrInvalidString(errors.Instagram)
            }
            placeholder="Instagram"
            helperText={
              touched.Instagram &&
              errors.Instagram &&
              errors.Instagram
            }
            type="text"
            name="Instagram"
            onChange={handleChange}
            value={values.Instagram}
            size="small"
            variant="outlined"
            className="fixed-input"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Instagram />
                </InputAdornment>
              ),
            }}
          />
        </Grid2>
        <Grid2 xs={12} sm={12} md={3} lg={3}>
          <TextField
            error={
              touched.Twitter &&
              !isEmptyOrInvalidString(errors.Twitter)
            }
            placeholder="Twitter"
            helperText={
              touched.Twitter &&
              errors.Twitter &&
              errors.Twitter
            }
            type="text"
            name="Twitter"
            onChange={handleChange}
            value={values.Twitter}
            size="small"
            variant="outlined"
            className="fixed-input"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Twitter />
                </InputAdornment>
              ),
            }}
          />
        </Grid2>
        <Grid2 xs={12} sm={12} md={3} lg={3}>
          <TextField
            error={
              touched.TikTok &&
              !isEmptyOrInvalidString(errors.TikTok)
            }
            placeholder="TikTok"
            helperText={
              touched.TikTok &&
              errors.TikTok &&
              errors.TikTok
            }
            type="text"
            name="TikTok"
            onChange={handleChange}
            value={values.TikTok}
            size="small"
            variant="outlined"
            className="fixed-input"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <div style={{ width: "25px" }}>
                    <TikTokIcon color={grey[500]} />
                  </div>
                </InputAdornment>
              ),
            }}
          />
        </Grid2>
      </Grid2> */}
    </Box>
  );
};
export default ContactInformationForm;
